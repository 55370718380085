import React from "react";
import {SettingsDoc, User} from "../../types/Types";
import LocationComments from "./LocationComments";

interface Props {
    match: {
        params: {
            id: string
        }
    },
    currentUser: User | undefined,
    usersMap: Map<string, User>
    settings: SettingsDoc
}

function MyLocation(props: Props) {
    return (
        <LocationComments settings={props.settings} usersMap={props.usersMap} currentUser={props.currentUser} match={props.match}/>
    );
}

export default MyLocation;