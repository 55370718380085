import React, {Component, useEffect, useState} from "react";
import {db, functions} from '../../firebase/FirebaseProvider'
import {doc, collection, getDoc, getDocs, Timestamp, query, orderBy, onSnapshot, where} from 'firebase/firestore'
import {Location, Rating, User, Visit} from "../types/Types";
import {Button, Card, Col, Form, InputGroup, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import history from "../util/History";
import {httpsCallable} from "@firebase/functions";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import PageTracking from "../auth/PageTracking";

interface RouteParams {
    id: string
}

function Admin() {

    const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
    const [location, setLocation] = useState<Location | undefined>(undefined);

    const [name, setName] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [city, setCity] = useState('');
    const [county, setCounty] = useState('');
    const [postcode, setPostcode] = useState('');
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');
    const [hotel, setHotel] = useState<boolean | undefined>(false);
    const [airport, setAirport] = useState<boolean | undefined>(false);
    const [pubIsTemporaryClosed, setPubIsTemporaryClosed] = useState<boolean  | undefined>(false);
    const [pubIsClosed, setPubIsClosed] = useState<boolean | undefined>(false);
    const [editMode, setEditMode] = useState<boolean | undefined>(true);
    const [saving, setSaving] = useState<boolean | undefined>(false);

    const params = useParams<RouteParams>();

    useEffect(() => {
        (async () => {
            const auth = getAuth();
            onAuthStateChanged(auth, async (currentUser) => {
                if (!currentUser) {
                    setCurrentUser(undefined);
                    return;
                }
                const userCollection = query(collection(db, 'users'), where('authId', '==', currentUser.uid));
                const userCollectionSnap = await getDocs(userCollection);
                userCollectionSnap.forEach(async (userDoc) => {
                    setCurrentUser(userDoc.data() as User);
                    if(!userDoc.data().admin) {
                        history.push('/');
                    }
                    if(params.id) {
                        await loadLocation(params.id);
                    } else {
                        setEditMode(false);
                    }
                    return;
                });
            });
        })();
    }, []);

    const loadLocation = async (locationId: string) => {
        const locationRef = doc(db, 'locations', locationId);
        const docSnap = await getDoc(locationRef);
        if (docSnap.exists()) {
            const location = docSnap.data() as Location;
            if(!location) {
                return;
            }
            setLocation(location);
            setName(location.name);
            setAddressLine1(location.addressLine1);
            setCity(location.city);
            setCounty(location.county);
            setPostcode(location.postcode);
            setLng(location.lng);
            setLat(location.lat);
            setAirport(location.airport);
            setHotel(location.hotel);
            setPubIsTemporaryClosed(location.pubIsTemporaryClosed);
            setPubIsClosed(location.pubIsClosed);
         }
    }

    const generateAddress = () => {
        var address = '';
        if(addressLine1) {
            address = addressLine1;
        }
        if(addressLine1 && city) {
            address = address + ", ";
        }
        if(city) {
            address = address + city;
        }
        if(county) {
            address = address + ", " + county;
        }
        if(postcode) {
            address = address + ", " + postcode;
        }
        return address;
    }

    const saveLocation = () => {
        setSaving(true);
        if(!name || !addressLine1 || !city || !postcode || !lat || !lng) {
            console.log("something required was null");
            return;
        }
        let locationId = '';
        if(location) {
            locationId = location.id;
        }
        var editLocation = httpsCallable(functions, 'editLocation');
        editLocation({location: {
                id: locationId,
                name,
                addressLine1,
                city,
                county,
                postcode,
                lat,
                lng,
                hotel,
                airport,
                pubIsTemporaryClosed,
                pubIsClosed,
                address: generateAddress()
            }}).then(r => {
            history.push('/pubs');
        });
    }

    return (
        <>
            <PageTracking />
            <Card className="leaderboard my-3">
                <Card.Header>
                    {location && location.id && <div>Edit Location</div>}
                    {!location && <div>Create Location</div>}
                </Card.Header>
                <Card.Body>
                    <Row className={""}>
                        <Col className="">
                            <Form>

                                {location && location.id && <Form.Group className="mb-3" controlId="formBasicText">
                                    <Form.Label>ID</Form.Label>
                                    <Form.Control type="text" placeholder="ID" value={location.id} disabled/>
                                </Form.Group>}

                                {(location || !editMode) && <>

                                    <Form.Group className="mb-3" controlId="formBasicText">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" placeholder="Location name" value={name} onChange={e => setName(e.target.value)}/>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicText">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control type="text" placeholder="Address line 1" value={addressLine1} onChange={e => setAddressLine1(e.target.value)}/>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicText">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control type="text" placeholder="City" value={city}  onChange={e => setCity(e.target.value)}/>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicText">
                                        <Form.Label>County</Form.Label>
                                        <Form.Control type="text" placeholder="County" value={county}  onChange={e => setCounty(e.target.value)}/>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicText">
                                        <Form.Label>Postcode</Form.Label>
                                        <Form.Control type="text" placeholder="Postcode" value={postcode}  onChange={e => setPostcode(e.target.value)}/>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicText">
                                        <Form.Label>Latitude</Form.Label>
                                        <Form.Control type="text" placeholder="Latitude" value={lat}  onChange={e => setLat(e.target.value)}/>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicText">
                                        <Form.Label>Longitude</Form.Label>
                                        <Form.Control type="text" placeholder="Longitude" value={lng} onChange={e => setLng(e.target.value)}/>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicCheckbox0">
                                        <Form.Check type="checkbox" label="Is a hotel?" checked={hotel} onChange={e => setHotel(e.target.checked)}/>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicCheckbox1">
                                        <Form.Check type="checkbox" label="In an airport?" checked={airport} onChange={e => setAirport(e.target.checked)}/>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicCheckbox2">
                                        <Form.Check type="checkbox" label="Renovating?" checked={pubIsTemporaryClosed} onChange={e => setPubIsTemporaryClosed(e.target.checked)}/>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicCheckbox3">
                                        <Form.Check type="checkbox" label="Closed?" checked={pubIsClosed} onChange={e => setPubIsClosed(e.target.checked)}/>
                                    </Form.Group>

                                </>}

                                <div style={{textAlign: "right"}}>
                                    <Button disabled={saving} variant="primary btn cursor-pointer" onClick={() => saveLocation()}>
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export default Admin;