import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBeer,
  faHammer,
  faPlane,
  faHotel,
  faTimes,
  faArrowLeft,
  faArrowRight,
  faDeleteLeft, faLocationDot, faStar
} from '@fortawesome/free-solid-svg-icons'
import {db, functions} from '../../firebase/FirebaseProvider'
import {doc, collection, getDoc, getDocs, limit, query, orderBy, onSnapshot, where} from 'firebase/firestore'
import {Location, Rating, User, Visit, HttpResult, NearestPub} from "../types/Types";
import LocationRow from "./LocationRow";
import {httpsCallable} from "@firebase/functions";
import {Form, Button, Card, Col, InputGroup, ListGroup, Row} from "react-bootstrap";
import history from "../util/History";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import AuthCheck from "../auth/AuthCheck";
import PageTracking from "../auth/PageTracking";
import CrosshairsTag from "../tag/CrosshairsTag";
import {formatKilometres, formatMetres} from "../util/Util";
import LocationTag from "../tag/LocationTag";
import LoadingTag from "../tag/LoadingTag";

interface State {
  loading: boolean;
  locations: Array<Location>;
  filteredLocations: Array<Location>;
  locationsMap: Map<string, Location>;
  currentUser: User;
  visitsMap: Map<string, Visit>;
  locationModifiedDate: Date;
  ratingModifiedDate: Date;
  visitModifiedDate: Date;
  sortType: string;
  queryString: string;
  filter: string;
  locationCount: number;
  openCount: number;
  pageStart: number;
  pageEnd: number;
  findingNearest: boolean;
  nearestPubId: string;
  nearestPubName: string;
  nearestPubFeedback: string;
}

interface Props {
  locations: Array<Location>;
}

class Locations extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      locations: [],
      filteredLocations: [],
      locationsMap: new Map<string, Location>(),
      currentUser: {} as User,
      visitsMap: new Map<string, Visit>(),
      locationModifiedDate: new Date(),
      ratingModifiedDate: new Date(),
      visitModifiedDate: new Date(),
      sortType: 'a-z',
      queryString: '',
      filter: '',
      locationCount: 0,
      openCount: 0,
      pageStart: 0,
      pageEnd: 50,
      findingNearest: false,
      nearestPubName: "",
      nearestPubId: "",
      nearestPubFeedback: "",
    }
  }

  componentDidMount() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (currentUser) => {
      if (!currentUser) {
        history.push('/');
        return;
      }
      const userCollection = query(collection(db, 'users'), where('authId', '==', currentUser.uid));
      const userCollectionSnap = await getDocs(userCollection);
      userCollectionSnap.forEach((userDoc) => {
        let userToSet: User = userDoc.data() as User;
        userToSet.id = userDoc.id;
        this.setState({ currentUser: userToSet }, () => {
          this.loadAllData();
        });
        return;
      });
    });
  }

  private async loadAllData() {

    let openCount = 0;
    const locationsMap: Map<string, Location> = new Map<string, Location>();
    this.props.locations.forEach((location) => {
      if(!location.pubIsClosed) {
        openCount++;
      }
      locationsMap.set(location.id, location as Location);
    });
    this.setState({ locationsMap, locationCount: this.props.locations.length, openCount });

    // Load visits
    const visitsMap: Map<string, Visit> = new Map<string, Visit>();
    const visitsOnceCollection = query(collection(db, 'visits'), where('userId', '==', this.state.currentUser.id));
    const visitsCollectionSnapshot = await getDocs(visitsOnceCollection);
    visitsCollectionSnapshot.forEach((doc) => {
      let tempVisit: Visit = doc.data() as Visit;
      visitsMap.set(tempVisit.locationId, tempVisit);
    });

    //Load a visit for every pub if one doesn't already exist
    this.props.locations.forEach((location) => {
      if(!visitsMap.get(location.id)) {
        visitsMap.set(location.id, {
          userId: this.state.currentUser.id,
          locationId: location.id,
          visited: false,
          lastModified: new Date()
        } as Visit);
      }
    });

    this.setState({ loading: false, visitsMap },() => {
      this.processLocations(this.props.locations, this.state.locationsMap, this.state.visitsMap);
    });
  }

  private async loadUpdatedLocations() {
    const visitsMap: Map<string, Visit> = this.state.visitsMap;
    const locationsMap: Map<string, Location> = this.state.locationsMap;
    const locationsCollection = query(collection(db, 'locations'), where("lastModified", ">=", this.state.locationModifiedDate));
    const locationsCollectionSnapshot = await getDocs(locationsCollection);
    locationsCollectionSnapshot.forEach((doc) => {
      let visitToChange: Visit = visitsMap.get(doc.id) as Visit;
      if(!visitToChange) {
        return;
      }
      let locationToUpdate = doc.data();
      locationToUpdate.visited = visitToChange.visited;
      locationsMap.set(doc.id, locationToUpdate as Location);
      if(locationsMap) {
        this.setState({ locationsMap, locationModifiedDate: new Date()});
      }
    });
  }

  private async loadUpdatedVisits() {
    if(!this.state.currentUser) {
      return;
    }
    const visitsMap: Map<string, Visit> = this.state.visitsMap;
    const visitsCollection = query(collection(db, 'visits'), where('userId', '==', this.state.currentUser.id), where("lastModified", ">", this.state.visitModifiedDate));
    const visitsCollectionSnapshot = await getDocs(visitsCollection);
    visitsCollectionSnapshot.forEach((doc) => {
      let tempVisit: Visit = doc.data() as Visit;
      visitsMap.set(tempVisit.locationId, tempVisit);
      this.setState({ visitsMap, visitModifiedDate: new Date() });
    });
  }

  private processLocations = (locations: Array<Location>, locationsMap: Map<string, Location>, visits: Map<string, Visit>) => {
    locations.map((location) => {

      // Set if location is visited
      if(this.state.currentUser && visits) {
        let visitCheck = visits.get(location.id);
        if(visitCheck && visitCheck.visited) {
          location.visited = true;
        } else {
          location.visited = false;
        }
      }

      // Set the updated location properties
      let updatedLocation = locationsMap.get(location.id);
      if(updatedLocation) {
        location.visitCount = updatedLocation.visitCount;
        location.ratingCountLow = updatedLocation.ratingCountLow;
        location.ratingCountHigh = updatedLocation.ratingCountHigh;
      }

      locationsMap.set(location.id, location);
    });

    this.setState({locationsMap});

    const queryString = this.state.queryString.toLowerCase().replaceAll(" ", "");
    const queryStringMax = queryString.substring(0, queryString.length-1) + String.fromCharCode(queryString.charCodeAt(queryString.length-1) + 1);

    const sortType = this.state.sortType;
    const filter = this.state.filter;

    let sortedLocations = locations
        .filter(function(a: Location){
          if(!filter) {
            return a;
          } else if(filter && filter === 'temp' && a.pubIsTemporaryClosed) {
            return a;
          } else if(filter && filter === 'closed' && a.pubIsClosed) {
            return a;
          } else if(filter && filter === 'airport' && a.airport) {
            return a;
          } else if(filter && filter === 'hotel' && a.hotel) {
            return a;
          } else if(filter && filter === 'open' && (!a.pubIsClosed && !a.pubIsTemporaryClosed)) {
            return a;
          }
        })
        .filter(function(a: Location){
          if(!queryString) {
            return a;
          } else {

            let nameToCheck = '';
            let cityToCheck = '';
            let countyToCheck = '';

            if(a.name) {
              nameToCheck = a.name.toLowerCase().replaceAll(" ", "");
            }
            if(a.city) {
              cityToCheck = a.city.toLowerCase().replaceAll(" ", "");
            }
            if(a.county) {
              countyToCheck = a.county.toLowerCase().replaceAll(" ", "");
            }

            return (nameToCheck >= queryString
                && nameToCheck < queryStringMax)
                || (cityToCheck >= queryString
                    && cityToCheck < queryStringMax)
                || (countyToCheck >= queryString
                    && countyToCheck < queryStringMax);
          }
        })
        .sort(function(a: Location, b: Location) {
          if(sortType === 'visits') {
            return b.visitCount - a.visitCount;
          } else if(sortType === 'sessions') {
            return b.sessionUniqueCount - a.sessionUniqueCount;
          } else {
            if(a.name > b.name) {
              return 1;
            } else if(a.name < b.name) {
              return -1;
            } else {
              return 0;
            }
          }
    });
    this.setState({
      filteredLocations: sortedLocations,
      locationCount: sortedLocations.length,
      pageStart: 0,
      pageEnd: 50
    });
  }

  filteredLocationsSlice = (): Array<Location> => {
    return this.state.filteredLocations.slice(this.state.pageStart, this.state.pageEnd);
  }

  toggleVisited = (locationId: string) => {

    var toggleVisitedFunction = httpsCallable(functions, 'toggleVisitedV2');
    toggleVisitedFunction({locationId: locationId}).then(r => {
      this.loadUpdatedVisits();
      this.loadUpdatedLocations();
    });

    const visitsMap: Map<string, Visit> = this.state.visitsMap;
    let visitToChange: Visit = visitsMap.get(locationId) as Visit;
    if(!visitToChange) {
      return;
    }
    const visited = visitToChange.visited;
    if(visited) {
      visitToChange.visited = false;
    } else {
      visitToChange.visited = true;
    }
    visitsMap.set(locationId, visitToChange);

    this.setState({ visitsMap }, () => {
      this.updateLocationsMap(locationId, visited);
    });
  }

  updateLocationsMap = (locationId: string, visited: boolean) => {
    const locationsMap: Map<string, Location> = this.state.locationsMap;
    let locationToChange: Location = locationsMap.get(locationId) as Location;
    if (!locationToChange) {
      return;
    }
    if (!locationToChange.visitCount && locationToChange.visitCount !== 0) {
      locationToChange.visitCount = 0;
    } else if (visited) {
      locationToChange.visitCount--;
      locationToChange.visited = false;
    } else {
      locationToChange.visitCount++;
      locationToChange.visited = true;
    }
    locationsMap.set(locationId, locationToChange);
    this.setState({locationsMap});
  };

  pageLeft = () => {
    const pageSize = 50;
    if(this.state.pageStart > 0) {
      let newStart = this.state.pageStart - pageSize;
      let newEnd = this.state.pageEnd - pageSize;
      if(newStart < 0) {
        this.setState({pageStart: 0, pageEnd: pageSize})
      } else {
        this.setState({pageStart: newStart, pageEnd: newEnd})
      }
    }
  }

  pageRight = () => {
    const pageSize = 50;
    if(this.state.pageEnd < this.state.locationCount) {
      let newStart = this.state.pageStart + pageSize;
      let newEnd = this.state.pageEnd + pageSize;
      if(newEnd > this.state.locationCount) {
        this.setState({pageStart: this.state.locationCount - pageSize, pageEnd: this.state.locationCount})
      } else {
        this.setState({pageStart: newStart, pageEnd: newEnd})
      }
    }
  }

  handleSort = (sortType: string) => {
    this.setState({sortType}, () => {
      this.processLocations(this.props.locations, this.state.locationsMap, this.state.visitsMap);
    });
  }

  handleFilter = (filter: string) => {
    this.setState({filter}, () => {
      this.processLocations(this.props.locations, this.state.locationsMap, this.state.visitsMap);
    });
  }

  handleClear = () => {
    this.setState({queryString: '', filter: ''}, () => {
      this.processLocations(this.props.locations, this.state.locationsMap, this.state.visitsMap);
    });
  };

  handleSearch = (queryString: string) => {
    this.setState({queryString}, () => {
      this.processLocations(this.props.locations, this.state.locationsMap, this.state.visitsMap);
    });
  };

  locateNearestPub = () => {
    this.setState({findingNearest: true});
    navigator.geolocation.getCurrentPosition(
        async currentLocation => {
          try {
            const findNearestLocation = httpsCallable(functions, 'findNearestLocation');
            await findNearestLocation({
                coords: {
                  lat: currentLocation.coords.latitude,
                  lng: currentLocation.coords.longitude
                }
            }).then(r => {
              if(r.data) {
                const nearestPub = r.data as NearestPub;
                this.setState({
                  nearestPubId: nearestPub.locationId,
                  nearestPubName: nearestPub.locationName,
                  nearestPubFeedback: formatKilometres(nearestPub.distance),
                  findingNearest: false
                });
              } else {
                window.location.assign(window.location + "");
              }
            });
          } catch (err) {
            console.error(err);
          }
        },
        err => {
          alert(
              "Unable to get your location. Make sure you've enabled GPS! " +
              err.message
          );
          this.setState({findingNearest: false});
        },
        {
          maximumAge: 1000,
          enableHighAccuracy: true
        }
    );
  };

  render() {
    return (
        <>
          <PageTracking />
          <AuthCheck />

          {(this.state.currentUser && !this.state.currentUser.socialDisabled) && <Card>
            <div className={"d-inline-block p-2"}>
              <div className={"d-inline-block"} style={{float: "right"}}>
                <div className={"d-inline-block mr-1"}>
                  <small>
                    <Button className={"btn-sm"} onClick={() => {this.locateNearestPub()}}>
                      {!this.state.findingNearest && <CrosshairsTag/>}
                      {this.state.findingNearest && <LoadingTag/>}
                    </Button>
                  </small>
                </div>
              </div>
              <div className={"d-inline-block mt-1 ml-1 mr-2"} style={{float: "right"}}>
                {this.state.nearestPubFeedback === "" && <span>Find your nearest pub</span>}
                {this.state.nearestPubFeedback !== "" && <div className={"d-inline-block"}>
                    <LocationTag name={this.state.nearestPubName} id={this.state.nearestPubId} session={undefined} displayVerificationTag={false} linksToTab={"comments"}/>
                    <span className={"mr-1"}><b>{this.state.nearestPubFeedback}</b></span>
                </div>}
              </div>
            </div>
          </Card>}

        <Card className="my-3">
          <Card.Header>
            <Row style={{marginLeft: "-22px", marginRight: "-22px"}}>
              <Col className="pr-0">
                <Form.Group className={"mb-0"}>
                  <InputGroup>
                    <Form.Control
                        type="text"
                        placeholder="Search..."
                        name="location-search"
                        autoComplete="off"
                        value={this.state.queryString}
                        onChange={(e) => {this.handleSearch(e.target.value as string)}}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col className="col-auto pl-2 pr-0 mt-1 mr-0">
                <Button className={"btn-danger btn-sm"} onClick={(e) => {this.handleClear()}}>
                    <FontAwesomeIcon className={'icon'} icon={faDeleteLeft}  />
                </Button>
              </Col>
              <Col className="col-auto ml-0 pl-2 col mt-1">
                <div>
                  <Button className={"btn-sm mr-2 cursor-pointer"} onClick={() => {this.handleFilter("open")}}><FontAwesomeIcon className={this.state.filter === 'open' ? 'icon icon-open' : 'icon'} icon={faBeer} /></Button>
                  <Button className={"btn-sm mr-2 cursor-pointer"} onClick={() => {this.handleFilter("temp")}}><FontAwesomeIcon className={this.state.filter === 'temp' ? 'icon icon-temporary' : 'icon'} icon={faHammer} /></Button>
                  <Button className={"btn-sm mr-2 cursor-pointer"} onClick={() => {this.handleFilter("closed")}}><FontAwesomeIcon className={this.state.filter === 'closed' ? 'icon icon-closed' : 'icon'} icon={faTimes} /></Button>
                  <Button className={"btn-sm mr-2 cursor-pointer"} onClick={() => {this.handleFilter("airport")}}><FontAwesomeIcon className={this.state.filter === 'airport' ? 'icon icon-plane' : 'icon'} icon={faPlane} /></Button>
                  <Button className={"btn-sm cursor-pointer"} onClick={() => {this.handleFilter("hotel")}}><FontAwesomeIcon className={this.state.filter === 'hotel' ? 'icon icon-hotel' : 'icon'} icon={faHotel} /></Button>
                </div>
              </Col>
            </Row>
          </Card.Header>
        </Card>

        <Card className="my-3">
          <Card.Header>
            <Row style={{marginLeft: "-22px", marginRight: "-22px"}}>
              {this.state.locationCount > 0 && <Col className={"col-auto"}>
                <div style={{display: "inline-block", marginRight: "0.3em"}}>Total: <span style={{color: "pink"}}>{this.props.locations.length}</span></div>
                <div style={{display: "inline-block"}}> / Open: <span style={{color: "lightgreen"}}>{this.state.openCount}</span></div>
              </Col>}
              <Col className="col-auto ml-auto">
                <div>
                  <Button className={"btn-sm mr-2 cursor-pointer"} disabled={this.state.sortType === 'a-z'} onClick={() => {this.handleSort("a-z")}}>A-Z</Button>
                  <Button className={"btn-sm mr-2 cursor-pointer"} disabled={this.state.sortType === 'visits'} onClick={() => {this.handleSort("visits")}}>Visits</Button>
                  <Button className={"btn-sm cursor-pointer"} disabled={this.state.sortType === 'sessions'} onClick={() => {this.handleSort("sessions")}}><FontAwesomeIcon icon={faLocationDot} /></Button>
                </div>
              </Col>
            </Row>
          </Card.Header>
          <ListGroup variant="flush">
            {(this.state.filteredLocations.length === 0 && this.state.loading) && <div className={'loading'}>
              Loading...
            </div>}
            {(this.state.filteredLocations.length === 0 && !this.state.loading) && <div className={'loading'}>
              No pubs match the search criteria
            </div>}
            {this.filteredLocationsSlice().map((location: Location, index: number) => (
                <LocationRow
                    sortType={this.state.sortType}
                    visit={this.state.visitsMap.get(location.id)}
                    key={location.id}
                    location={this.state.locationsMap.get(location.id)}
                    toggleVisited={this.toggleVisited}
                    position={index + 1}
                    admin={this.state.currentUser.admin} />
            ))}
          </ListGroup>
          <Card.Footer>
            <Row>
              <div className={'w-100'} style={{textAlign: 'right'}}>
                <div>
                  <Button disabled={this.state.pageStart === 0} className={'btn-sm mr-2 cursor-pointer'} onClick={() => {this.pageLeft()}}><FontAwesomeIcon className={'icon'} icon={faArrowLeft} /></Button>
                  <div className={'d-inline-block ml-1'}>{this.state.pageStart + 1}</div>
                  <div className={'d-inline-block ml-1'}>to</div>
                  <div className={'d-inline-block ml-1 mr-1'}>{this.state.pageEnd}</div>
                  <Button disabled={this.state.pageEnd >= this.state.locationCount} className={'btn-sm mr-2 cursor-pointer ml-2'} onClick={() => {this.pageRight()}}><FontAwesomeIcon className={'icon'} icon={faArrowRight} /></Button>
                </div>
              </div>
            </Row>
          </Card.Footer>
        </Card>

        <Card className={'mb-3'}>
          <Card.Footer>
            <Row>
              <Col xs="6" sm="auto" className="text-center">{" "}
                <FontAwesomeIcon className="icon-open mr-1" icon={faBeer} />{" "}
                Open
              </Col>
              <Col xs="6" sm="auto" className="text-center">{" "}
                <FontAwesomeIcon className="icon-temporary mr-1" icon={faHammer} />{" "}
                Renovating
              </Col>
              <Col xs="6" sm="auto" className="text-center">
                <FontAwesomeIcon className="icon-closed mr-1" icon={faTimes} />{" "}
                Closed
              </Col>
              <Col xs="6" sm="auto" className="text-center">
                <FontAwesomeIcon className="icon-plane mr-1" icon={faPlane} />{" "}
                Airport
              </Col>
              <Col xs="6" sm="auto" className="text-center">
                <FontAwesomeIcon className="icon-hotel mr-1" icon={faHotel} />{" "}
                Hotel
              </Col>
              <Col xs="6" sm="auto" className="text-center">
                <FontAwesomeIcon className="icon-bonus mr-1" icon={faStar} />{" "}
                Bonus
              </Col>
            </Row>
          </Card.Footer>
        </Card>
        </>
    );
  }
}

export default Locations;