import {NewsItem, User} from "../../types/Types";
import React from "react";
import {Link} from "react-router-dom";
import {addAnchorTags, getDate, getTime} from "../../util/Util";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserTag from "../../users/tag/UserTag";
import LocationTag from "../../tag/LocationTag";

interface Props {
    comment: NewsItem;
    commentUser: User | undefined;
    user: User;
    deleteComment: (arg0: string) => void;
}

function CommentRow(props: Props) {

    return (
        <>
            {<div className={"news-item"}>
                <div>
                    <div className={"d-inline-block"}><UserTag
                        user={props.commentUser}
                        visit={undefined}
                        session={undefined}
                        padRight={false}
                        hideLocationDot={true}
                        linksToTab={"comments"}
                    /></div>
                    <span className={"ml-1 mr-1"}>
                        "<span dangerouslySetInnerHTML={{ __html: addAnchorTags(props.comment.payload)}} />"
                    </span>
                </div>
                <div>
                    <div style={{float: "right"}}>
                        <small>
                            {(props.user.id == props.comment.userId || props.user.admin) &&
                            <FontAwesomeIcon style={{cursor: "pointer"}}
                                             onClick={() => {props.deleteComment(props.comment.id)}}
                                             icon={faTrash} />}
                        </small>
                        <small className={"ml-2 d-inline-block"}>
                            <div className={"d-inline-block"}>{getDate(props.comment.lastModified)} at {getTime(props.comment.lastModified)}</div>
                        </small>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default CommentRow;