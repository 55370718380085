import React, {Component, useEffect, useState} from "react";
import {Card, ListGroup} from "react-bootstrap";
import {Location, Comment, NewsItem, User, CommentInfo, Visit, Session, SettingsDoc} from "../../types/Types";
import {collection, doc, getDoc, getDocs, limit, orderBy, query, where} from "@firebase/firestore";
import {db, functions} from "../../../firebase/FirebaseProvider";
import LocationNav from "./LocationNav";
import LocationDetails from "./LocationDetails";
import {getDate, getTime, LOCATION_LIMIT, sanitizeHtmlString} from "../../util/Util";
import LocationCheckIn from "./LocationCheckIn";
import UserTag from "../../users/tag/UserTag";
import DateTime from "../../date/DateTime";
import PageTracking from "../../auth/PageTracking";

interface Props {
    match: {
        params: {
            id: string
        }
    },
    currentUser: User | undefined,
    usersMap: Map<string, User> | undefined
    session: Session | undefined;
    settings: SettingsDoc | undefined;
}

function LocationVisits(props: Props) {

    const [location, setLocation] = useState<Location | undefined>(undefined);
    const [visits, setVisits] = useState<Array<Visit> | undefined>(undefined);

    useEffect(() => {
        (async () => {
            const locationRef = doc(db, 'locations', props.match.params.id);
            const docSnap = await getDoc(locationRef);
            if (docSnap.exists()) {
                let locationToSet = docSnap.data() as Location;
                locationToSet.id = docSnap.id;
                setLocation(locationToSet);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await loadVisits();
        })();
    }, [location]);

    const loadVisits = async() => {
        if(!location) {
            return;
        }
        const visits: Array<Visit> = new Array<Visit>();
        const visitsOnceCollection = query(collection(db, 'visits'),
            where('locationId', '==', location.id),
            where("visited", "==", true),
            orderBy('lastModified', 'desc'),
            limit(LOCATION_LIMIT));
        const visitsCollectionSnapshot = await getDocs(visitsOnceCollection);
        visitsCollectionSnapshot.forEach((doc) => {
            let tempVisit: Visit = doc.data() as Visit;
            if(isUserSocial(tempVisit.userId)) {
                visits.push(tempVisit);
            }
        });
        setVisits(visits);
    };

    const getUserId = (visitId: string): string => {
        if(!props.usersMap) {
            return "";
        }
        let user: User | undefined = props.usersMap.get(visitId);
        if(!user) {
            return "";
        }
        return user.id;
    };

    const getUser = (visitId: string): User => {
        if(!props.usersMap) {
            return {} as User;
        }
        let user: User | undefined = props.usersMap.get(visitId);
        if(!user) {
            return {} as User;
        }
        return user
    };

    const isUserSocial = (visitId: string): boolean => {
        if(!props.usersMap) {
            return false;
        }
        let user: User | undefined = props.usersMap.get(visitId);
        if(!user) {
            return false;
        }
        return !user.socialDisabled;
    };

    return (
        <>
            <PageTracking />
            {props.currentUser && <LocationDetails location={location} currentUser={props.currentUser} />}
            {location && props.currentUser && !props.currentUser.socialDisabled && <LocationCheckIn currentUser={props.currentUser} location={location}/>}
            {location && <LocationNav settings={props.settings} currentUser={props.currentUser} locationId={location.id}/>}
            <Card>
                {location && visits && visits.length > 0 && <Card.Header>
                    <div className={"d-inline-block float-left"}>
                        <span>Verified: <b>{visits && visits.filter((visit) => {return visit.verified}).length}</b></span>
                    </div>
                </Card.Header>}
                {(props.usersMap && visits) && visits.map(visit => (
                    <div key={getUserId(visit.userId)} className={"user-row"}>
                        <UserTag visit={visit} session={props.session} user={getUser(visit.userId)} padRight={true} hideLocationDot={false} linksToTab={"visits"} />
                        <DateTime date={visit.lastModified}/>
                    </div>
                ))}
            </Card>
        </>
    )
}

export default LocationVisits;