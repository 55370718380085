import React from "react";
import "../nav/Navigation.css";
import {Link, NavLink} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import {User} from "../types/Types";
import {Card} from "react-bootstrap";
import {getTime} from "../util/Util";
import {doc, setDoc, updateDoc} from "@firebase/firestore";
import {db} from "../../firebase/FirebaseProvider";

interface Props {
    user: User | undefined
}

function UserAdmin(props: Props) {

  const toggleChat = async(toggleState: boolean) => {
    if(!props.user) {
      return;
    }
    const userRef = doc(db, 'users', props.user.id);
    await updateDoc(userRef, {chatEnabled: toggleState});
    window.location.assign(window.location + "");
  };

  return (
      <>
        {/* <Card className={"mb-2"}>*/}
        {/*    <Card.Body style={{padding: "0.5em"}}>*/}
        {/*        <div>*/}
        {/*            <div className={"d-inline-block"} style={{float: "right"}}>*/}
        {/*                <div className={"d-inline-block"}>*/}
        {/*                  <>*/}
        {/*                      {(props.user && (props.user.chatEnabled != undefined && !props.user.chatEnabled)) && <button className={"btn btn-primary"} onClick={() => {toggleChat(true)}}>Re-enable chat</button>}*/}
        {/*                      {(props.user && (props.user.chatEnabled == undefined || props.user.chatEnabled)) && <button className={"btn btn-secondary"} onClick={() => {toggleChat(false)}}>Disable chat</button>}*/}
        {/*                  </>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </Card.Body>*/}
        {/*</Card>*/}
      </>
  );
}

export default UserAdmin;
