import React, {useEffect, useState} from "react";
import {Session, User} from "../types/Types";
import {Form, Card, Col, Row} from "react-bootstrap";
import LeaderboardNav from "./LeaderboardNav";
import {
    addNumberSuffix,
    calculatePoints,
    formatPoints,
} from "../util/Util";
import {collection, getDocs, query, where} from "@firebase/firestore";
import {db} from "../../firebase/FirebaseProvider";
import UserRow from "./UserRow";
import {Link} from "react-router-dom";
import PageTracking from "../auth/PageTracking";

interface Props {
    users: Array<User>;
    userMap: Map<string, User>;
    currentUser: User | undefined;
    sessionsMap: Map<string, Session>;
}

function LeaderboardYear(props: Props) {
    const getCurrentYear = (): string => {
        return new Date().getFullYear() + '';
    };

    const getYearsList = (): number[] => {
        const currentYear = new Date().getFullYear();
        const startYear = 2023;
        const years = [];
        for (let year = currentYear; year >= startYear; year--) {
            years.push(year);
        }
        return years;
    };

    interface SessionCount { totalCount: number, uniqueCount: number }
    interface SessionRank { user: User, points: number }

    const [loading, setLoading] = useState<boolean>(true);
    const [year, setYear] = useState<string>(getCurrentYear());
    const [sessions, setSessions] = useState<Array<Session>>();
    const [rankedUsers, setRankedUsers] = useState<Array<SessionRank>>();
    const [rank, setRank] = useState<number>(-1);

    const updateYear = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setLoading(true);
        setRank(-1);
        setYear(event.target.value);
    };

    const getYearStartAndEnd = (year: string) => {
        const startOfYear = new Date(`${year}-01-01T00:00:00.000Z`);
        const endOfYear = new Date(`${year}-12-31T23:59:59.999Z`);
        return { startOfYear, endOfYear };
    };

    const countUniqueLocations = (sessions: Session[]): Map<string, SessionCount> => {
        const userLocationMap = new Map<string, Set<string>>();
        const userLocationArray = new Map<string, Array<string>>();

         sessions.forEach(({ userId, locationId }) => {
             if (!userLocationMap.has(userId)) {
                userLocationMap.set(userId, new Set());
             }
             if (!userLocationArray.has(userId)) {
                 userLocationArray.set(userId, new Array());
             }
             userLocationMap.get(userId)!.add(locationId);
             userLocationArray.get(userId)!.push(locationId);
        });

        const uniqueLocationCountMap = new Map<string, SessionCount>();
        userLocationMap.forEach((locations, userId) => {
            uniqueLocationCountMap.set(userId, {
                totalCount: userLocationArray.get(userId)!.length,
                uniqueCount: locations.size
            } as SessionCount);
        });

        return uniqueLocationCountMap;
    };

    const getSortedUsers = (rankedUsers: Array<SessionRank>) => {
        return rankedUsers
            .filter((rankedUser) => {return rankedUser.user && !rankedUser.user.socialDisabled})
            .sort((countA, countB) => {
                if(countA.points > countB.points) {
                    return -1;
                } else if(countA.points === countB.points)  {
                    return 0;
                } else {
                    return 1;
                }
            })
    };

    useEffect(() => {
        (async () => {
            const { startOfYear, endOfYear } = getYearStartAndEnd(year);
            let sessions = new Array<Session>();
            const sessionsOnceCollection = query(collection(db, 'sessions'),
                where('checkInTime', '>', startOfYear),
                where('checkInTime', '<', endOfYear)
            )
            const sessionsCollectionSnapshot = await getDocs(sessionsOnceCollection);
            sessionsCollectionSnapshot.forEach((doc) => {
                let tempSession = doc.data() as Session;
                tempSession.id = doc.id;
                sessions.push(tempSession);
            });
            setSessions(sessions);
        })();
    }, [year]);

    useEffect(() => {
        (async () => {
            const locationCountMap = countUniqueLocations(sessions || new Array());
            const rankedUsers = new Array<SessionRank>()
            locationCountMap.forEach((count, userId) => {
                if(props.userMap.get(userId) != undefined) {
                    rankedUsers.push({
                        user: props.userMap.get(userId),
                        points: calculatePoints(count.totalCount, count.uniqueCount, props.userMap.get(userId)!.bonusPoints || 0),
                    } as SessionRank)
                }
            });
            setRankedUsers(getSortedUsers(rankedUsers));
        })();
    }, [sessions, props.userMap]);

    useEffect(() => {
        (async () => {
            if(!rankedUsers) {
                return;
            }
            setRank(0)
            rankedUsers.map((rankedUser, index) => {
                if(props.currentUser && rankedUser.user && rankedUser.user.id === props.currentUser.id) {
                    setRank(index + 1);
                    setLoading(false);
                    return;
                }
            });
            setLoading(false);
        })();
    }, [rankedUsers, props.currentUser]);

    return (
        <>
            <PageTracking />
            <LeaderboardNav currentUser={props.currentUser} />
            {rankedUsers && <Card className={"mb-3"}>
                <Card.Header>
                    <Row style={{marginLeft: "-22px", marginRight: "-22px"}}>
                        <Col className={"col-auto"}>
                            {loading && <div>Calculating...</div>}
                            {(rank > 0) && <div>You're ranked: {rank}<small>{addNumberSuffix(rank)}</small> out of {rankedUsers.length}</div>}
                            <div>
                                <small>
                                    <Link to={"/support#points-help"}>How are points calculated?</Link>
                                </small>
                            </div>
                        </Col>
                        <Col className="col-auto ml-auto">
                            <div>
                                <Form.Control as="select" id="dropdown" value={year} onChange={updateYear}>
                                    {getYearsList().map((year) => (
                                        <option key={year} value={year}>{year}</option>
                                    ))}
                                </Form.Control>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body style={{paddingTop: "0.4em"}}>
                    {loading && <div className={'loading'}>
                        Loading...
                    </div>}
                    {loading == false && rankedUsers.map((sessionRank: SessionRank, index: number) => (
                        <UserRow countToDisplay={formatPoints(sessionRank.points).toString()} key={sessionRank.user.id} index={index} user={sessionRank.user} session={props.sessionsMap.get(sessionRank.user.id)}/>
                    ))}
                </Card.Body>
            </Card>}
        </>
    );
}

export default LeaderboardYear;