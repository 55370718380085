import React from "react";
import "../nav/Navigation.css";
import {getDate, getTime} from "../util/Util";

interface Props {
  date: Date
}

function DateTime(props: Props) {
    return (
        <div>
          <div style={{float: "right"}}>
            <small className={"ml-2 d-inline-block"}>
              <div className={"d-inline-block"}>{getDate(props.date)} at {getTime(props.date)}</div>
            </small>
          </div>
        </div>
    )
}

export default DateTime;
