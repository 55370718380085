import React, {useEffect} from "react";
import {useLocation} from "react-router";
import ReactGA from "react-ga4";

function PageTracking() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: location.pathname + location.search });

  }, [location]);

  return (
      <></>
  )
}

export default PageTracking;