import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import {Location, Photo, SettingsDoc, User} from "../types/Types";
import {collection, doc, getDoc, getDocs, limit, orderBy, query, where} from "@firebase/firestore";
import {db, functions, storage} from "../../firebase/FirebaseProvider";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import LocationNav from "../locations/profile/LocationNav";
import LocationDetails from "../locations/profile/LocationDetails";
import {generateUUID, isPhotoEnabled, PHOTOS_LIMIT, resizeImage} from "../util/Util";
import LocationCheckIn from "../locations/profile/LocationCheckIn";
import PhotoRow from "../locations/profile/PhotoRow";
import {httpsCallable} from "@firebase/functions";
import PhotoTag from "../tag/PhotoTag";
import AuthCheck from "../auth/AuthCheck";
import PageTracking from "../auth/PageTracking";
import LabelTag from "../tag/LabelTag";

interface Props {
    match: {
        params: {
            tag: string
        }
    },
    currentUser: User | undefined
    usersMap: Map<string, User>
}

function PhotosViewer(props: Props) {

    const [photos, setPhotos] = useState<Array<Photo> | undefined>(undefined);

    useEffect(() => {
        (async () => {
            await loadPhotos();
        })();
    }, []);

    const loadPhotos = async() => {
        // Load photos
        let photos: Array<Photo> = [];
        const photosOnceCollection = query(collection(db, 'photos'),
            where('hidden', '==', false),
            orderBy('createdAt', 'desc'),
            limit(PHOTOS_LIMIT));
        const photosOnceCollectionSnapshot = await getDocs(photosOnceCollection);
        photosOnceCollectionSnapshot.forEach((doc) => {
            let tempPhoto = doc.data() as Photo;
            tempPhoto.id = doc.id;
            photos.push(tempPhoto);
        });
        setPhotos(photos);
    };

    const hidePhoto = (photoId: string): void => {
        const hidePhoto = httpsCallable(functions, 'hidePhoto');
        hidePhoto(
            {
                photoId
            }
        ).then(r => {
            window.location.assign(window.location + "");
        });
    };

    const filteredPhotos = () => {
        if(!photos) {
            return []
        }
        return photos.filter((photo) => {
            return photo.tags.includes(props.match.params.tag)
        })
    };

    return (
        <>
            <PageTracking />
            <AuthCheck />
            <PageTracking />
            <Card className={"mb-2"}>
                <div className={"card-alt-padding"}>
                    <LabelTag/> <PhotoTag name={props.match.params.tag} />
                </div>
            </Card>
            <Card className={"mb-3"}>
                <div>
                    {!photos && <div className={'loading'}>
                        Loading...
                    </div>}
                    {(photos && filteredPhotos().length == 0) &&<div className={'loading'}>
                        No photos yet
                    </div>}
                    <div className={"album"}>
                        {photos && filteredPhotos()
                            .map((photo: Photo, index: number) => (
                            props.currentUser && <PhotoRow
                                key={photo.id + index}
                                currentUser={props.currentUser}
                                photo={photo}
                                deletePhoto={() => hidePhoto(photo.id)}
                                photoUser={props.usersMap.get(photo.userId)}
                                displayLocation={true}
                            />
                        ))}
                    </div>
                </div>
            </Card>
        </>
    )
}

export default PhotosViewer;