import React, {useEffect, useState} from "react";
import {Location, Session, User} from "../../types/Types";
import {Card} from "react-bootstrap";
import {httpsCallable} from "@firebase/functions";
import {db, functions} from "../../../firebase/FirebaseProvider";
import {collection, getDocs, query, where} from "@firebase/firestore";
import {formatMetres, getTime} from "../../util/Util";
import {Link} from "react-router-dom";

interface Props {
    location: Location,
    currentUser: User
}

function LocationCheckIn(props: Props) {

    const [checkInStatus, setCheckInStatus] = useState<number | undefined>(-1);
    const [hasCheckedIn, setHasCheckedIn] = useState<boolean>(false);
    const [session, setSession] = useState<Session>();
    const [feedback, setFeedback] = useState<string>();

    useEffect(() => {
        (async () => {
            await fetchSessionStatus();
        })();
    }, [props.currentUser]);

    const fetchSessionStatus = async() => {
        const sessionCollection = query(collection(db, 'sessions'),
            where('userId', '==', props.currentUser.id),
            where('checkOutTime', '>', new Date())
        );
        const newsOnceCollectionSnapshot = await getDocs(sessionCollection);
        let count = 0;
        newsOnceCollectionSnapshot.forEach((doc) => {
            count++;
            let sessionItem = doc.data() as Session;
            setCheckInStatus(1);
            setHasCheckedIn(true);
            setSession(sessionItem);
            return;
        });
        if(count == 0) {
            setCheckInStatus(0);
            setHasCheckedIn(false);
        }
    }

    const handleCheckIn = () => {
        setHasCheckedIn(true);
        navigator.geolocation.getCurrentPosition(
            async currentLocation => {
                try {
                    var checkIn = httpsCallable(functions, 'checkIn');
                    await checkIn({sessionRequest: {
                            locationId: props.location.id,
                            locationName: props.location.name,
                            lat: currentLocation.coords.latitude,
                            lng: currentLocation.coords.longitude
                        }}).then(r => {
                            if(r.data && r.data != 1) {
                                setHasCheckedIn(false);
                                setCheckInStatus(0);
                                setFeedback("Move " + formatMetres(r.data as number) + " closer.");
                            } else {
                                window.location.assign(window.location + "");
                            }
                        });
                } catch (err) {
                    console.error(err);
                    setCheckInStatus(0);
                }
            },
            err => {
                alert(
                    "Unable to get your location. Make sure you've enabled GPS! " +
                    err.message
                );
            },
            {
                maximumAge: 1000,
                enableHighAccuracy: true
            }
        );
    }

    const handleCheckOut = () => {
        if(!session) {
            return
        }

        setHasCheckedIn(false);
        navigator.geolocation.getCurrentPosition(
            async currentLocation => {
                try {
                    var checkOut = httpsCallable(functions, 'checkOut');
                    await checkOut({sessionRequest: {
                            locationId: session.locationId,
                            locationName: session.locationName,
                            lat: currentLocation.coords.latitude,
                            lng: currentLocation.coords.longitude
                        }}).then(r => {
                        if(r.data && r.data != 1) {
                            setHasCheckedIn(true);
                            setCheckInStatus(1);
                            setFeedback("Move " + formatMetres(r.data as number) + " closer.");
                        } else {
                            window.location.assign(window.location + "");
                        }
                    });
                } catch (err) {
                    console.error(err);
                    setCheckInStatus(1);
                }
            },
            err => {
                alert(
                    "Unable to get your location. Make sure you've enabled GPS! " +
                    err.message
                );
            },
            {
                maximumAge: 1000,
                enableHighAccuracy: true
            }
        );
    }

    const pubIsClosed = props.location.pubIsClosed || props.location.pubIsTemporaryClosed;

    return (
        <>
            {props.currentUser && <Card className={"mb-2"}>
                <Card.Body style={{padding: "0.5em"}}>
                    <>
                        {(checkInStatus == 0 || !session) && <div>
                            <div className={"d-inline-block"} style={{float: "left"}}>
                                <div className={"ml-1 d-inline-block"} style={{marginTop: "0.1em"}}>
                                    <span>{feedback}</span>
                                </div>
                            </div>
                            <div className={"d-inline-block"} style={{float: "right"}}>
                                <div className={"d-inline-block"}>
                                    {pubIsClosed && <span>Pub is closed.</span>}
                                    {!pubIsClosed && <>
                                        {!feedback && <span>Here now?</span>}
                                        <button disabled={pubIsClosed} className={"btn btn-sm btn-primary ml-2"} onClick={() => handleCheckIn()}>
                                        {!hasCheckedIn && <span>Check in</span>}
                                        {hasCheckedIn && <span>Please wait...</span>}
                                        </button>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>}
                        {checkInStatus == 1 && session && <div>
                            {feedback && <div className={"d-inline-block"} style={{float: "left"}}>
                                <div className={"ml-1 d-inline-block"} style={{marginTop: "0.1em"}}>
                                    <span>{feedback}</span>
                                </div>
                            </div>}
                            {!feedback &&
                                <>
                                    <div className={""}>
                                        <span>Checked into</span>
                                        <div className={"d-inline-block ml-1 mr-1"}>
                                            <Link to={`/location/${session.locationId}/comments/me`} className={"p-0"}>
                                                <div className={"location-tag"}>{session.locationName}</div>
                                            </Link>
                                        </div>
                                        {session.checkOutTime && <>
                                            <span>until </span>
                                            <div className={"d-inline-block"}>
                                                <span>{getTime(session.checkOutTime)}</span>
                                            </div>
                                        </>}
                                    </div>
                                    <div className={"mt-1"} style={{float: "right"}}>
                                        <div className={"d-inline-block"}>
                                            <button className={"btn btn-sm btn-primary ml-2 btn-danger"} onClick={() => handleCheckOut()}>
                                                {hasCheckedIn && <span>Check out</span>}
                                                {!hasCheckedIn && <span>Please wait...</span>}
                                            </button>
                                        </div>
                                    </div>
                            </>
                            }
                        </div>}
                    </>
                </Card.Body>
            </Card>}
        </>
    );
}

export default LocationCheckIn;