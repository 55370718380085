import React, {Component} from "react";
import {Location, Visit} from "../types/Types";
import {Col, Row} from "react-bootstrap";
import "../../App.css";
import VisitElement from "./VisitElement";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHammer, faPlane, faHotel, faTimes, faPencilAlt, faStar} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";
import VerificationTag from "../tag/VerificationTag";

interface Props {
    sortType: string;
    location: Location | undefined;
    toggleVisited: (locationId: string) => void;
    position: number;
    admin: boolean;
    visit: Visit | undefined;
}

function LocationRow(props: Props) {
    return (
        <>
            {props.location && <Row className="align-middle flex-nowrap pub-row">
                <Col className="d-flex flex-column align-items-start justify-content-center text-truncate">
                    <div>
                        <Link to={`/location/${props.location.id}/comments`} className={"p-0"}>
                            <div className={"location-tag"}>{props.location.name}</div>
                        </Link>
                        {props.visit && props.visit.verified && <div className={"d-inline-block ml-1"}><VerificationTag active={false}/></div>}
                        {props.location.airport && <small className="text-truncate">
                            <FontAwesomeIcon className="ml-1 animated zoomIn icon-plane" icon={faPlane} />
                        </small>}
                        {props.location.hotel && <small className="text-truncate">
                            <FontAwesomeIcon className="ml-1 animated zoomIn icon-hotel" icon={faHotel} />
                        </small>}
                        {props.location.pubIsClosed && <small className="text-truncate">
                            <FontAwesomeIcon className="ml-1 animated zoomIn icon-closed" icon={faTimes} />
                        </small>}
                        {(props.location.pubIsTemporaryClosed && !props.location.pubIsClosed) && <small className="text-truncate">
                            <FontAwesomeIcon className="ml-1 animated zoomIn icon-temporary" icon={faHammer} />
                        </small>}
                        {(props.location.bonus) && <small className="text-truncate">
                            <FontAwesomeIcon className="ml-1 animated zoomIn icon-bonus" icon={faStar} />
                        </small>}
                        {props.admin && <small className="text-truncate">
                            <Link to={`/admin/${props.location.id}`}>
                                <FontAwesomeIcon className="ml-1 animated zoomIn icon-pen cursor-pointer" icon={faPencilAlt} />
                            </Link>
                        </small>}
                    </div>
                    {props.location && (
                        <div>
                            <small className="text-truncate">
                                {props.location.address}
                            </small>
                        </div>
                    )}
                </Col>
                <Col className="col-auto d-flex">
                     <VisitElement
                         displaySessions={props.sortType == 'sessions'}
                         sessionCount={props.location.sessionUniqueCount}
                         visitCount={props.location.visitCount}
                         visited={props.location.visited}
                         id={props.location.id}
                         toggleVisited={props.toggleVisited} />
                </Col>
            </Row>}
        </>
    )
}

export default LocationRow;