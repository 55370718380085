import React from "react";
import "../nav/Navigation.css";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import {User} from "../types/Types";
import AuthCheck from "../auth/AuthCheck";

interface Props {
  user: User | undefined
  currentUser: User | undefined
}

function UserNav(props: Props) {

    return (
        <Container>
          <AuthCheck />
          <Nav variant="tabs" className="mb-2 mr-auto sub-menu-nav">
                {props.user && <>
                    <NavLink className="nav-link" to={`/profile/${props.user.id}/visits`}>
                        Visits
                    </NavLink>
                    {(props.currentUser && !props.currentUser.socialDisabled) && <NavLink className="nav-link" to={`/profile/${props.user.id}/sessions`}>
                        Check-ins
                    </NavLink>}
                    {(props.currentUser && !props.currentUser.socialDisabled) && <NavLink className="nav-link" to={`/profile/${props.user.id}/comments`}>
                        Comments
                    </NavLink>}
                    {(props.currentUser && !props.currentUser.socialDisabled) && <NavLink className="nav-link" to={`/profile/${props.user.id}/photos`}>
                        Photos
                    </NavLink>}
                </>}
            </Nav>
        </Container>
    )
}

export default UserNav;
