import React from "react";
import "../nav/Navigation.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationCrosshairs} from "@fortawesome/free-solid-svg-icons";

function CrosshairsTag() {

    return (
        <>
            <div className={`d-inline-block`}>
                <FontAwesomeIcon className="animated zoomIn fa-solid" icon={faLocationCrosshairs}/>
            </div>
        </>
    )
}

export default CrosshairsTag;
