import React from "react";
import "../nav/Navigation.css";
import {Link} from "react-router-dom";

interface Props {
  name: string;
}

function PhotoTag(props: Props) {

    return (
        <>
            <Link to={"/photos/" + props.name}>
              <div className={`user-tag d-inline-block`}>
                {props.name}
              </div>
            </Link>
        </>
    )
}

export default PhotoTag;
