import {Session, User} from "../types/Types";
import React from "react";
import {Link} from "react-router-dom";
import {getDateFromTimeStamp} from "../util/Util";
import UserTag from "../users/tag/UserTag";
import DateTime from "../date/DateTime";
import LocationTag from "../tag/LocationTag";

interface Props {
    session: Session;
    user: User | undefined;
    displayName: boolean;
    displayLocation: boolean;
    displayBonus: boolean;
}

function SessionRow(props: Props) {

    const isCheckedIn = props.session && getDateFromTimeStamp(props.session.checkOutTime) > new Date() || false;

    return (
        <>
            <div className={`news-item`}>

                <div>
                    {props.displayName && <>
                        <div className={`d-inline-block`}>
                            {props.user &&
                                <UserTag
                                    user={props.user}
                                    visit={undefined}
                                    session={props.session}
                                    padRight={false}
                                    hideLocationDot={props.displayLocation}
                                    linksToTab={"sessions"}
                                    displayBonus={props.displayBonus}
                                />
                            }
                        </div>
                    </>}
                    {(props.displayName && props.displayLocation) && <>
                        <div className={"d-inline-block mr-1"}>
                            {!isCheckedIn && <span>checked into</span>}
                            {isCheckedIn && <span>is checked into</span>}
                        </div>
                    </>}
                    {props.displayLocation &&
                        <LocationTag
                            name={props.session.locationName}
                            id={props.session.locationId}
                            session={props.session}
                            displayVerificationTag={true}
                            linksToTab={"sessions"}
                        />
                    }
                </div>
                <DateTime date={props.session.checkInTime} />
            </div>
        </>
    )
}

UserTag.defaultProps = { displayBonus: false, };

export default SessionRow;