import {NewsItem, Session, User} from "../types/Types";
import React from "react";
import {Link} from "react-router-dom";
import {addAnchorTags} from "../util/Util";
import UserTag from "../users/tag/UserTag";
import DateTime from "../date/DateTime";
import LocationTag from "../tag/LocationTag";

interface Props {
    newsItem: NewsItem;
    user: User | undefined;
    session: Session | undefined;
}

function NewsItemRow(props: Props) {

    const getNewsContent = (newsItem: NewsItem): string => {
        let news = "";

        if(newsItem.type === 'VISIT') {
            news = " ticked ";
        } if(newsItem.type === 'VISIT' && newsItem.verified) {
            news = " visited ";
        } else if(newsItem.type === 'LOCATION' && newsItem.subType === 'UPDATE') {
            news = " updated ";
        } else if(newsItem.type === 'LOCATION' && newsItem.subType === 'CREATION') {
            news = " added ";
        } else if(newsItem.type === 'COMMENT') {
            news = " commented on "
        }

        return news;
    };

    const getTabToLinkTo = (newsItem: NewsItem): string => {
        let tab = "";
        if(newsItem.type === 'COMMENT') {
            tab = "comments"
        } else {
            tab = "visits"
        }
        return tab;
    };

    return (
        <>
            {<div className={"news-item"}>

                <div>
                    <div className={"d-inline-block"}>
                        {props.user && <UserTag user={props.user} visit={undefined} session={undefined} padRight={false} hideLocationDot={false} linksToTab={getTabToLinkTo(props.newsItem)}/>}
                    </div>
                    <div className={"d-inline-block mr-1"}>
                        {getNewsContent(props.newsItem)}
                    </div>
                    <LocationTag
                        name={props.newsItem.locationName}
                        id={props.newsItem.locationId}
                        displayVerificationTag={props.newsItem.verified}
                        session={props.session}
                        linksToTab={getTabToLinkTo(props.newsItem)}
                    />
                    {props.newsItem.type === 'COMMENT' && <span>
                        "<span dangerouslySetInnerHTML={{ __html: addAnchorTags(props.newsItem.payload)}} />"
                    </span>}
                </div>

                <div className={"mt-1"} >
                    {props.newsItem.type === 'VISIT' &&
                    <div style={{float: "left"}}>
                        <small>
                            <>
                                {(Number(props.newsItem.payload).toString() !== 'NaN' &&
                                    Number(props.newsItem.payload).toString() !== '0')
                                && <div className={"d-inline-block mr-1"}>
                                    Visit number {Number(props.newsItem.payload).toString()}
                                </div>}
                                {(Number(props.newsItem.payload).toString() === 'NaN' &&
                                    Number(props.newsItem.payload).toString() !== '0')
                                && <div className={"d-inline-block mr-2"}>
                                    Visit number 1
                                </div>}
                            </>
                        </small>
                    </div>}

                    <DateTime date={props.newsItem.lastModified}/>
                </div>
            </div>}
        </>
    )
}

export default NewsItemRow;