import React, {Component, useEffect, useRef, useState} from "react";
import {db} from '../../../firebase/FirebaseProvider'
import {
    doc,
    collection,
    getDoc,
    getDocs,
    Timestamp,
    query,
    orderBy,
    onSnapshot,
    where,
    limit,
    setDoc
} from 'firebase/firestore'
import {Location, User, Visit} from "../../types/Types";
import {Card} from "react-bootstrap";
import LocationRow from "../LocationRow";
import UserDetails from "../UserDetails";
import UserNav from "../UserNav";
import UserAdmin from "../UserAdmin";
import PageTracking from "../../auth/PageTracking";

interface Props {
    match: {
        params: {
            id: string
        }
    },
    locations: Array<Location> | undefined,
    currentUser: User | undefined,
}

function UserVisits(props: Props) {

    const [user, setUser] = useState<User | undefined>(undefined);
    const [processedLocations, setProcessedLocations] = useState<Array<Location> | undefined>(undefined);
    const [visits, setVisits] = useState<Array<Visit>>(new Array<Visit>());
    const [visitsMap, setVisitsMap] = useState<Map<string, Visit> | undefined>(undefined);

    useEffect(() => {
        (async () => {
            const userRef = doc(db, 'users', props.match.params.id);
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
                let userToSet = docSnap.data() as User;
                userToSet.id = docSnap.id;
                setUser(userToSet);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await loadAllData();
        })();
    }, [user]);

    useEffect(() => {
        (async () => {
            await processLocations();
        })();
    }, [props.locations, visitsMap]);

    const loadAllData = async() => {
        if(!user) {
            return;
        }

        // Load visits
        const visitsMap: Map<string, Visit> = new Map<string, Visit>();
        const visitsArray: Array<Visit> = new Array<Visit>();
        const visitsOnceCollection = query(collection(db, 'visits'), where('userId', '==', user.id));
        const visitsCollectionSnapshot = await getDocs(visitsOnceCollection);
        visitsCollectionSnapshot.forEach((doc) => {
            let tempVisit: Visit = doc.data() as Visit;
            visitsMap.set(tempVisit.locationId, tempVisit);
            visitsArray.push(tempVisit)
        });
        setVisitsMap(visitsMap);
        setVisits(visitsArray)
    }

    const processLocations = async() => {
        const visits = visitsMap as Map<string, Visit>;
        if(!props.locations) {
            return [];
        }
        props.locations.map((location) => {
            if (user && visitsMap) {
                let visitCheck = visits.get(location.id);
                if (visitCheck && visitCheck.visited) {
                    location.visited = true;
                } else {
                    location.visited = false;
                }
            }
        });
        const locationsSorted = props.locations
            .filter(location => location.visited)
            .sort((locationA, locationB) => {
                if(locationA.name > locationB.name) {
                    return 1;
                } else if(locationA.name === locationB.name)  {
                    return 0;
                } else {
                    return -1;
                }
            });
        setProcessedLocations(locationsSorted);
    };

    return (
        <>
            <PageTracking />
            <UserDetails user={user}/>
            {(props.currentUser && props.currentUser.userAdmin) && <UserAdmin user={user}/>}
            <UserNav currentUser={props.currentUser} user={user}/>
            <Card className={"mb-3"}>
                {(visits && visits.filter((visit) => {return visit.verified}).length > 0) && <Card.Header>
                    <div className={"d-inline-block float-left"}>
                        <span>Verified: <b>{visits && visits.filter((visit) => {return visit.verified}).length}</b></span>
                    </div>
                </Card.Header>}
                <Card.Body className={"p-0"}>
                    {processedLocations && <>
                        {visitsMap && processedLocations.map((location: Location, index: number) => (
                            <LocationRow visit={visitsMap.get(location.id)} index={index} location={location} />
                        ))}
                    </>}
                    {!processedLocations && <>
                        <div className={"loading"}>Loading...</div>
                    </>}
                </Card.Body>
            </Card>
        </>
    );
}

export default UserVisits;