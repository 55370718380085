import React, {Component, useEffect, useState} from "react";
import {Session, User} from "../types/Types";
import {Card, Col} from "react-bootstrap";
import UserRow from "./UserRow";
import LeaderboardNav from "./LeaderboardNav";
import {addNumberSuffix, formatTime} from "../util/Util";
import PageTracking from "../auth/PageTracking";

interface Props {
    users: Array<User>;
    currentUser: User | undefined;
    sessionsMap: Map<string, Session>;
}

function LeaderboardSessions(props: Props) {

    const [rank, setRank] = useState<number>(0);
    const [rankedVerified, setRankedVerified] = useState<Array<User> | undefined>();

    useEffect(() => {
        (async () => {
            const verifiedArray: Array<User> = props.users
                .filter((user) => {return user.sessionCount > 0})
                .sort((a: User, b: User) => {return b.sessionCount - a.sessionCount})
            setRankedVerified(verifiedArray);
        })();
    }, [props.users]);

    useEffect(() => {
        (async () => {
            if(!rankedVerified) {
                return;
            }
            setRank(0)
            rankedVerified.map((user, index) => {
                if(props.currentUser && user.id === props.currentUser.id) {
                    setRank(index + 1);
                    return;
                }
            });
        })();
    }, [rankedVerified, props.currentUser]);

    return (
        <>
            <PageTracking />
            {props.currentUser && <>
            <LeaderboardNav currentUser={props.currentUser} />
            {rankedVerified && <Card className={"mb-3"}>
                <Card.Header>
                    {(rank === 0) && <div>You are yet to check-in.</div>}
                    {(rank > 0) && <div>You're ranked: {rank}<small>{addNumberSuffix(rank)}</small> out of {rankedVerified.length}</div>}
                </Card.Header>
                <Card.Body style={{paddingTop: "0.4em"}}>
                    {rankedVerified
                        .filter((user) => {return !user.socialDisabled})
                        .map((user: User, index: number) => (
                            <UserRow countToDisplay={formatTime(user.sessionCount)} key={user.id} index={index} user={user} session={props.sessionsMap.get(user.id)}/>
                        ))}
                    {(rankedVerified.length == 0 || rank === 0) && <div className={'loading'}>
                        Loading...
                    </div>}
                </Card.Body>
            </Card>}
        </>}
        </>
    );
}

export default LeaderboardSessions;