import {Photo, User, Visit} from "../../types/Types";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {getDate, getTime} from "../../util/Util";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserTag from "../../users/tag/UserTag";
import PhotoTag from "../../tag/PhotoTag";
import CameraTag from "../../tag/CameraTag";
import LabelTag from "../../tag/LabelTag";
import VerificationTag from "../../tag/VerificationTag";
import {Modal} from "react-bootstrap";
import history from "../../util/History";

interface Props {
    photo: Photo;
    photoUser: User | undefined;
    currentUser: User;
    deletePhoto: (arg0: string) => void;
    displayLocation: boolean
}

function PhotoRow(props: Props) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentUrl, setCurrentUrl] = useState<String>(window.location.pathname);

  useEffect(() => {
    setCurrentUrl(window.location.pathname)
  }, []);

  const handleClose = () => {
    setShowModal(false);
    window.removeEventListener('popstate', handlePopState);
  };

  const handleOpen = () => {
    setShowModal(true);
    window.addEventListener('popstate', handlePopState);
  };

  const handlePopState = () => {
    handleClose()
    history.push(`${currentUrl}`);
  };

  return (
      <>
          <div className={"photo"}>
              <div onClick={() => handleOpen()}>
                  <img className={"full-size-photo"} src={props.photo.smallSizeUrl} />
              </div>
              <div className={"ml-2"} style={{textAlign: "left"}}>
                  <div className={"d-inline-block mt-2 mb-1"}>
                      <small>
                          <CameraTag />
                          <div className={"d-inline-block ml-1"}>
                            <UserTag user={props.photoUser} visit={undefined} session={undefined} padRight={true} hideLocationDot={false} linksToTab={"photos"} />
                          </div>
                      </small>
                  </div>
                  <div className={""}>
                      {props.displayLocation && <div className={""}>
                          <small>
                            <VerificationTag active={false}/>
                            <div className={"d-inline-block ml-1"}>
                              <Link to={`/location/${props.photo.locationId}/photos`} className={"p-0"}>
                                <div className={`location-tag`}>{props.photo.locationName}</div>
                              </Link>
                            </div>
                          </small>
                      </div>}
                      {!props.displayLocation && props.photo.tags.length > 0 &&
                      <small>
                          <LabelTag/>
                          <div className={"d-inline-block ml-1"}>{!props.displayLocation && props.photo.tags.map((tag) => (
                              <div className={"d-inline-block mr-1"}>
                                <PhotoTag name={tag}/>
                              </div>
                          ))}
                          </div>
                      </small>
                      }
                      {!props.displayLocation && props.photo.tags.length == 0 &&
                          <span><small><i>&nbsp;</i></small></span>
                      }
                  </div>
              </div>
              <div className={"mr-2"} >
                  <div style={{float: "right"}}>
                      <small>
                          {(props.currentUser.id == props.photo.userId || props.currentUser.photoAdmin) &&
                          <FontAwesomeIcon style={{cursor: "pointer"}}
                                           onClick={() => {props.deletePhoto(props.photo.id)}}
                                           icon={faTrash} />}
                      </small>
                      <small className={"ml-2 d-inline-block"}>
                          <div className={"d-inline-block"}>{getDate(props.photo.createdAt)} at {getTime(props.photo.createdAt)}</div>
                      </small>
                  </div>
              </div>
          </div>
          <Modal show={showModal} onHide={() => handleClose()}>
            <img style={{width: "100%", height: "100%"}}
                 src={props.photo.fullSizeUrl}
                 onClick={() => handleClose()} />
          </Modal>
      </>
    )
}

export default PhotoRow;