import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import {Photo, User} from "../../types/Types";
import {collection, doc, getDoc, getDocs, limit, orderBy, query, where} from "@firebase/firestore";
import {db, functions} from "../../../firebase/FirebaseProvider";
import {httpsCallable} from "@firebase/functions";
import UserDetails from "../UserDetails";
import UserNav from "../UserNav";
import PhotoRow from "../../locations/profile/PhotoRow";
import {PHOTOS_LIMIT} from "../../util/Util";
import PageTracking from "../../auth/PageTracking";

interface Props {
    match: {
        params: {
            id: string
        }
    },
    currentUser: User | undefined
}

function UserPhotos(props: Props) {

    const [user, setUser] = useState<User | undefined>(undefined);
    const [photos, setPhotos] = useState<Array<Photo> | undefined>(undefined);

    useEffect(() => {
        (async () => {
            const userRef = doc(db, 'users', props.match.params.id);
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
                let userToSet = docSnap.data() as User;
                userToSet.id = docSnap.id;
                setUser(userToSet);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await loadPhotos();
        })();
    }, [user]);

    const loadPhotos = async() => {
        if(!user) {
            return;
        }

        let photos: Array<Photo> = [];
        const photosOnceCollection = query(collection(db, 'photos'),
            where('userId', '==', user.id),
            where('hidden', '==', false),
            orderBy('createdAt', 'desc'),
            limit(PHOTOS_LIMIT));
        const commentsOnceCollectionSnapshot = await getDocs(photosOnceCollection);
        commentsOnceCollectionSnapshot.forEach((doc) => {
            let tempPhoto = doc.data() as Photo;
            tempPhoto.id = doc.id;
            photos.push(tempPhoto);
        });
        setPhotos(photos);
    }

    const hidePhoto = (photoId: string): void => {
        const hidePhoto = httpsCallable(functions, 'hidePhoto');
        hidePhoto(
            {
                photoId
            }
        ).then(r => {
            window.location.assign(window.location + "");
        });
    };

    return (
        <>
            <PageTracking />
            {props.currentUser && <UserDetails user={user} />}
            {user && <UserNav currentUser={props.currentUser} user={user} />}
            <Card className={"mb-3"}>
                <div>
                    {!photos && <div className={'loading'}>
                        Loading...
                    </div>}
                    {(photos && photos.length == 0) &&<div className={'loading'}>
                        No photos yet
                    </div>}
                    <div className={"album"}>
                        {photos && photos
                            .map((photo: Photo, index: number) => (
                            props.currentUser && <PhotoRow
                                key={photo.id + index}
                                currentUser={props.currentUser}
                                photo={photo}
                                deletePhoto={() => hidePhoto(photo.id)}
                                photoUser={user}
                                displayLocation={false}
                            />
                        ))}
                    </div>
                </div>
            </Card>
        </>
    )
}

export default UserPhotos;