import React from "react";
import "../../nav/Navigation.css";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import {isPhotoEnabled} from "../../util/Util";
import {SettingsDoc, User} from "../../types/Types";

interface Props {
    locationId: string;
    currentUser: User | undefined;
    settings: SettingsDoc | undefined;
}

function LocationNav(props: Props) {

    return (
        <Container>
            <Nav variant="tabs" className="mt-2 mb-2 mr-auto sub-menu-nav">
                <>
                    <NavLink className="nav-link" to={`/location/${props.locationId}/comments`}>
                        Comments
                    </NavLink>
                    {isPhotoEnabled(props.currentUser, props.settings) && <NavLink className="nav-link" to={`/location/${props.locationId}/photos`}>
                        Photos
                    </NavLink>}
                    <NavLink className="nav-link" to={`/location/${props.locationId}/visits`}>
                        Visits
                    </NavLink>
                    {(props.currentUser && !props.currentUser.socialDisabled) && <NavLink className="nav-link" to={`/location/${props.locationId}/sessions`}>
                        Check-ins
                    </NavLink>}
                </>
            </Nav>
        </Container>
    )
}

export default LocationNav;
