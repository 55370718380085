import React from "react";
import "../../nav/Navigation.css";
import {Link} from "react-router-dom";
import {Session, User, Visit} from "../../types/Types";
import VerificationTag from "../../tag/VerificationTag";
import AdminTag from "../../tag/AdminTag";
import {getDateFromTimeStamp, truncateString} from "../../util/Util";
import SupporterTag from "../../tag/SupporterTag";
import ChampionTag from "../../tag/ChampionTag";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";


interface Props {
    user: User | undefined
    visit: Visit | undefined
    session: Session | undefined
    padRight: Boolean | undefined
    hideLocationDot: Boolean
    linksToTab: String
    displayBonus: Boolean
}


function UserTag(props: Props) {

    const isCheckedIn = (props.session && getDateFromTimeStamp(props.session.checkOutTime) > new Date()) || false;

    const shouldDisplayLocationDot = (((props.visit && props.visit.verified) || isCheckedIn) && !props.hideLocationDot);

    const padRight = (props.user && !props.user.badgeDisabled) && (props.user.admin || props.user.supporter || props.user.champion) && props.padRight;

    return (
        <div className={padRight ? "mr-1" : ""}>
            {props.user && <Link to={`/profile/${props.user.id}/${props.linksToTab}`} className={"p-0 mr-1"}>
                <div className={`user-tag ${isCheckedIn ? "active-session" : ""}`}>
                    <div className={"d-inline-block"}>{truncateString(props.user.name)}</div>
                </div>
            </Link>}
            {props.user && !props.user.badgeDisabled && <div className={`d-inline-block ${(!props.user.badgeDisabled && (props.user.admin || props.user.supporter || props.user.champion)) ? "mr-1" : ""}`}>
                {props.user.admin && <div className={`d-inline-block`}>
                    <AdminTag active={isCheckedIn} />
                </div>}
                {!props.user.admin && !props.user.badgeDisabled && props.user.supporter && <div className={`d-inline-block`}>
                    <SupporterTag active={isCheckedIn} />
                </div>}
                {(!props.user.admin && !props.user.badgeDisabled && props.user.champion > 0) && <div className={`d-inline-block`}>
                    <ChampionTag year={props.user.champion} active={isCheckedIn} />
                </div>}
            </div>}
            {shouldDisplayLocationDot && <div className={"d-inline-block"}>
                <VerificationTag active={isCheckedIn}/>
            </div>}
            {(props.displayBonus) && <small className="text-truncate">
                <FontAwesomeIcon className="ml-1 animated zoomIn icon-bonus" icon={faStar} />
            </small>}
        </div>
    )
}

UserTag.defaultProps = { displayBonus: false, };

export default UserTag;
