import {SettingsDoc, User} from "../types/Types";

export function addAnchorTags (input: string): string  {
    const regex = /((?:https?|ftp):\/\/[\w\-_]+(?:\.[\w\-_]+)+[\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])\b/gi;
    return input.replace(regex, '<a href="$1">$1</a>');
}

export function sanitizeHtmlString(htmlString: string): string | null {
    // Create a new DOMParser instance
    const parser = new DOMParser();
    // Parse the HTML string into a DocumentFragment
    const doc = parser.parseFromString(htmlString, "text/html");
    // Use the textContent property to get the sanitized string
    const sanitizedString = doc.documentElement.textContent;

    if(!sanitizedString) {
        return "";
    }

    return sanitizedString;
}

export function getDate(lastModified: Date): string {
    var now: any = lastModified;
    const date: Date = new Date(now.seconds * 1000);
    return date.toDateString();
}

export function getTime(lastModified: Date): string {
    var now: any = lastModified;
    const date: Date = new Date(now.seconds * 1000);
    return date.toTimeString().substring(0, 5);
}

export function getMessageDate(messageSent: Date): string {
    const now: any = messageSent;
    const date: Date = new Date(now.seconds * 1000);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    //const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear().toString().slice(2);
    return `${hours}:${minutes} - ${day} ${month} ${year}`;
}

export function getDatePlusHours(now: Date, hours: number): Date {
    return new Date(now.getTime() + (hours * 60 * 60 * 1000));
}

export function formatMetres(metres: number): string {
    if (metres < 1) {
        return `${metres * 100} centimetres`;
    } else if (metres < 1000) {
        return `${metres.toFixed(0)} metres`;
    } else {
        return `${Number((metres / 1000).toFixed(0)).toLocaleString()} kilometres`;
    }
}

export function formatKilometres(kilometres: number): string {
    if (kilometres < 1) {
        return `${(kilometres * 1000).toFixed(0)} m`;
    } else if (kilometres < 10) {
        return `${(kilometres).toFixed(1)} km`;
    } else {
        return `${Number((kilometres).toFixed(0)).toLocaleString()} km`;
    }
}

export const LOCATION_LIMIT = 3000;
export const USERS_LIMIT = 10000;
export const NOTIFICATION_LIMIT = 100;
export const PHOTOS_LIMIT = 500;

export function addNumberSuffix(number: number): string {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
        return "th";
    }

    switch (lastDigit) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
}

export function formatTime(hours: number): string {
    if (hours < 24) {
        return `${Math.floor(hours)}h`;
    }

    const days = Math.floor(hours / 24);
    const remainingHours = Math.floor(hours % 24);

    let result = `${days}d`;

    if (remainingHours > 0) {
        result += ` ${remainingHours}h`;
    }

    return result;
}

export function formatNumber(numberToFormat: number): string {
    return numberToFormat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatNumberToDays(numberToFormat: number): string {
    return formatNumber(Math.floor(numberToFormat / 24))
}

export function getDateFromTimeStamp(date: any): Date {
    return new Date(date.seconds * 1000);
}

export function truncateString(str: string): string {
    if (str.length > 21) {
        return str.substring(0, 21) + "...";
    } else if (str.length > 19) {
        return capitalizeFirstLetters(str);
    } else {
        return str;
    }
}

export const capitalizeFirstLetters = (text: string): string => {
    return text.replace(/\b\w+/g, word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
};

export function calculatePoints(totalSessions: number, uniqueSessions: number, bonusPoints: number | undefined) {
    return Math.floor(
        ((((totalSessions || 0) - (uniqueSessions || 0)) * 5) + ((uniqueSessions || 0) * 10)) + (bonusPoints || 0)
    );
}

export function formatPoints(num: number): string {
    if (num < 1000) {
        return num.toString();
    } else if (num < 10000) {
        return (Math.floor(num / 100) / 10).toFixed(1).replace('.0', '') + 'k';
    } else {
        return (Math.floor(num / 1000)) + 'k';
    }
}

export function calculateAndFormatPoints(user: User): string {
    return formatPoints(calculatePoints(user.sessionCount, user.sessionVerifiedCount, user.bonusPoints));
}

export const getGoogleMapsLink = (address: string): string => {
    // Replace spaces with plus signs in the address string
    const formattedAddress = address.replace(/ /g, "+");

    // Construct the Google Maps URL with the formatted address
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`;

    return googleMapsUrl;
};

export function isChatEnabled(user: User | undefined, settings: SettingsDoc | undefined): boolean {
    return ((user && !user.socialDisabled && user.chatEnabled == undefined && settings && settings.chatEnabled) || (user && !user.socialDisabled && user.chatEnabled)) == true
}

export function isPhotoEnabled(user: User | undefined, settings: SettingsDoc | undefined): boolean {
    return ((user && user.photoEnabled == undefined && settings && settings.photoEnabled) || (user && user.photoEnabled)) == true
}

export const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (crypto.getRandomValues(new Uint8Array(1))[0] % 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export function resizeImage(fileBlob: Blob, width: number, height: number) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const img = new Image();

            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d')!;

                // Draw the image on the canvas with the new size
                ctx.drawImage(img, 0, 0, width, height);

                // Convert the canvas to a Blob
                canvas.toBlob((blob) => {
                    resolve(blob);
                }, 'image/jpeg');
            };

            img.onerror = (error) => {
                reject(error);
            };

            console.log(event!.target!.result);
            img.src = event!.target!.result as string;
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsDataURL(fileBlob);
    });
}
