import React from "react";
import "../nav/Navigation.css";
import {NavLink} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import {Notifications, User} from "../types/Types";

interface Props {
  currentUser: User | undefined
  notifications: Notifications | undefined
}

class MessagesNav extends React.PureComponent<Props, {}> {

  private shouldNotifySupport(): Boolean {
    if(window.location.href.includes("support")) {
      return false
    }
    if(this.props.currentUser == undefined) {
      return false
    }
    if(this.props.notifications == undefined || this.props.currentUser == undefined) {
      return false
    }
    if(this.props.notifications.lastSupportMessage == undefined) {
      return false
    }
    if(this.props.currentUser.messageChannels.supportLastSeen == undefined) {
      return false
    }
    return this.props.notifications.lastSupportMessage > this.props.currentUser.messageChannels.supportLastSeen;
  }

  private shouldNotifyGeneral(): Boolean {
    if(window.location.href.includes("general")) {
      return false
    }
    if(this.props.currentUser == undefined) {
      return false
    }
    if(this.props.notifications == undefined || this.props.currentUser == undefined) {
      return false
    }
    if(this.props.notifications.lastGeneralMessage == undefined) {
      return false
    }
    if(this.props.currentUser.messageChannels.generalLastSeen == undefined) {
      return false
    }
    return this.props.notifications.lastGeneralMessage > this.props.currentUser.messageChannels.generalLastSeen;
  }

  public render() {
    return (
        <Container>
          <Nav variant="tabs" className="mb-2 mr-auto sub-menu-nav">
            <>
              <NavLink className="nav-link" to="/chat/general" onClick={() => this.navigateToChannel("general")}>
                {!this.shouldNotifyGeneral() && <span>General</span>}
                {this.shouldNotifyGeneral() && <span><b>General</b><span style={{color: "red"}}>*</span></span>}
              </NavLink>
              <NavLink className="nav-link" to="/chat/support" onClick={() => this.navigateToChannel("support")}>
                {!this.shouldNotifySupport() && <span>Support</span>}
                {this.shouldNotifySupport() && <span><b>Support</b><span style={{color: "red"}}>*</span></span>}
              </NavLink>
            </>
          </Nav>
        </Container>
    );
  }

  private navigateToChannel = (channel: string) => {
    //history.push("/chat/" + channel);
    //window.location.reload();
  }
}

export default MessagesNav;
