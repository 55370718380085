import React, {useEffect, useState} from "react";
import {Card, ListGroup} from "react-bootstrap";
import {Message, Notifications, SettingsDoc, User} from "../types/Types";
import {isChatEnabled, sanitizeHtmlString} from "../util/Util";
import {httpsCallable} from "@firebase/functions";
import {db, functions} from "../../firebase/FirebaseProvider";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  doc,
  serverTimestamp,
  updateDoc
} from "@firebase/firestore";
import MessageRow from "./MessageRow";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MessagesNav from "./MessagesNav";
import PageTracking from "../auth/PageTracking";
import AuthCheck from "../auth/AuthCheck";

interface Props {
  currentUser: User | undefined;
  usersMap: Map<string, User>;
  setNotifications: (notifications: Notifications | undefined) => void;
  notifications: Notifications | undefined;
  selectedChannel: string;
  settings: SettingsDoc | undefined;
}

function Chat(props: Props) {
  const [messageSending, setMessageSending] = useState<boolean>(false);
  const [message, setMessage] = useState<string | undefined>();
  const [messages, setMessages] = useState<Map<string, Message>>(new Map<string, Message>());
  const [key, setKey] = useState(0);

  const refreshMessages = () => {
    setKey(prevKey => prevKey + 1);
  };

  const recordLastSeenAt = async (userId: string, channel: string) => {
    try {
      const docRef = doc(db, 'users', userId);
      let data = {};
      if(channel == "GENERAL") {
        data = {
          "messageChannels.lastSeen": serverTimestamp(),
          "messageChannels.generalLastSeen": serverTimestamp()
        };
      } else if(channel == "SUPPORT") {
        data = {
          "messageChannels.lastSeen": serverTimestamp(),
          "messageChannels.supportLastSeen": serverTimestamp()
        };
      }
      props.setNotifications(undefined);
      await updateDoc(docRef, data);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    onSnapshot(query(collection(db, 'messages'),
        where("channel", "==", props.selectedChannel),
        where("hidden", "==", false),
        orderBy("createdAt", "desc"),
        limit(200),
        ), (snapshot) => {
      snapshot.docChanges().forEach(message => {
        if (message.type === 'added' || message.type === 'modified') {
          messages.set(message.doc.data().id, message.doc.data() as Message);
        } else if(message.type === 'removed') {
          messages.delete(message.doc.data().id)
        }
      });
      refreshMessages();
    });
    if(props.currentUser) {
      recordLastSeenAt(props.currentUser!.id, props.selectedChannel);
    }
  }, [props.selectedChannel]);

  let sendMessage: (channel: string) => void;
  sendMessage = (channel: string) => {
    if (!message) {
      return;
    }
    const sanitizedString = sanitizeHtmlString(message);
    if (!sanitizedString) {
      return;
    }

    setMessageSending(true);

    const saveMessage = httpsCallable(functions, 'saveMessageV3');
    saveMessage({
      message: {
        payload: sanitizedString,
        channel: channel
      }
    }).then(r => {
      setMessage("");
      setMessageSending(false);
    });
  };

  const likeMessage = (commentId: string): void => {
  }

  const hideMessage = (messageId: string): void => {
    const hideMessage = httpsCallable(functions, 'hideMessage');
    hideMessage({message: { id: messageId }})
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      sendMessage(props.selectedChannel);
    }
  };

  const getMessageList = (messageMap: Map<string, Message>): Array<Message> => {
    return Array.from(messageMap.values()).sort((a, b) => {
      if(a.createdAt < b.createdAt) {
        return -1
      } else {
        return 1
      }
    })
  }

  return (
      <>
        <PageTracking />
        <AuthCheck />
        {isChatEnabled(props.currentUser, props.settings) && <>
        <MessagesNav {...props} notifications={props.notifications} currentUser={props.currentUser} />
        <Card className={"mb-3 message-view"} >
          <ListGroup variant="flush" style={{paddingTop: "10px", paddingBottom: "10px"}}>
            {!messages && <div className={'loading'}></div>}
            {messages && getMessageList(messages)
                .filter((message: Message) => message.channel == props.selectedChannel.toUpperCase())
                .map((message: Message, index: number) => (
                  props.currentUser && <MessageRow
                    key={message.id + index + key}
                    user={props.usersMap.get(message.userId)}
                    message={message}
                    hideMessage={hideMessage}
                    currentUser={props.currentUser}
                    previousMessage={getMessageList(messages)[index - 1]}
                  />
            ))}
          </ListGroup>
        </Card>
        {props.currentUser && <Card className={"mb-2"}>
            <div style={{padding: "0.8em"}}>
               {(props.currentUser.visitCount > 0) && <div className="form-group p-0 mb-0">
                  <div className={"d-inline-block chat-input"}>
                      <input disabled={messageSending} placeholder={"Type your message here..."} className="form-control" value={message} id="add-message" onChange={e => {setMessage(e.target.value)}} onKeyDown={handleKeyDown}></input>
                  </div>
                  <div className={"d-inline-block"} style={{float: "right", marginTop: "4px"}}>
                      <button disabled={messageSending} onClick={() => sendMessage(props.selectedChannel)} className={"btn btn-primary btn-sm"}>
                          <FontAwesomeIcon icon={faPaperPlane} />
                      </button>
                  </div>
               </div>}
              {(props.currentUser.visitCount == undefined || props.currentUser.visitCount == 0) && <div className={"d-inline-block"} style={{width: "100%"}}>
                 Please record at least one visit to chat.
               </div>}
            </div>
        </Card>}
        </>}
      </>
  )
}

export default Chat;