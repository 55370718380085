import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import { Route, Router } from "react-router";
import history from './components/util/History';
import Locations from './components/locations/Locations';
import LeaderboardVisits from './components/leaderboard/LeaderboardVisits';
import Navigation from "./components/nav/Navigation";
import Home from './components/home/Home';
import {Switch} from "react-router";
import Header from './components/page/Header';
// @ts-ignore
import AuthEmail from './components/auth/AuthEmail';
import MapElement from "./components/map/MapElement";
import Admin from "./components/admin/Admin";
import Support from "./components/support/Support";
import AuthEmailResponse from "./components/auth/AuthEmailResponse";
import {getAuth, onAuthStateChanged} from "@firebase/auth";
import {
    collection,
    getDocs,
    limit,
    orderBy,
    query,
    where,
    serverTimestamp,
    doc,
    updateDoc,
    onSnapshot
} from "@firebase/firestore";
import {db} from "./firebase/FirebaseProvider";
import {Location, Notifications, Session, SettingsDoc, Stats, User} from "./components/types/Types";
import Visits from "./components/hub/Visits";
import Comments from "./components/hub/Comments";
import Updates from "./components/hub/Updates";
import MyProfile from "./components/users/MyProfile";
import LocationComments from "./components/locations/profile/LocationComments";
import LocationVisits from "./components/locations/profile/LocationVisits";
// Google Analytics
import ReactGA from 'react-ga';
import {LOCATION_LIMIT, USERS_LIMIT} from "./components/util/Util";
import MyLocation from "./components/locations/profile/MyLocation";
import Sessions from "./components/hub/Sessions";
import UserVisits from "./components/users/tabs/UserVisits";
import UserSessions from "./components/users/tabs/UserSessions";
import LocationSessions from "./components/locations/profile/LocationSessions";
import LeaderboardVerified from "./components/leaderboard/LeaderboardVerified";
import LeaderboardSessions from "./components/leaderboard/LeaderboardSessions";
import Chat from "./components/chat/Chat";
import Settings from "./components/settings/Settings";
import LeaderboardYear from "./components/leaderboard/LeaderboardYear";
import LocationPhotos from "./components/locations/profile/LocationPhotos";
import Photos from "./components/hub/Photos";
import PhotosViewer from "./components/photos/PhotosViewer";
import UserComments from "./components/users/tabs/UserComments";
import UserPhotos from "./components/users/tabs/UserPhotos";
import LeaderboardPoints from "./components/leaderboard/LeaderboardPoints";
const TRACKING_ID = "G-27JNWX5874";
ReactGA.initialize(TRACKING_ID);

async function recordLastSeenAt(userId: string): Promise<void> {
    try {
        const docRef = doc(db, 'users', userId);
        const data = {
            lastSeenAt: serverTimestamp(),
            id: userId,
        };
        await updateDoc(docRef, data);
    } catch (error) {
        console.error("Error: ", error);
    }
}

const App: React.FC = () => {

    const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
    const [userSession, setUserSession] = useState<Session | undefined>(undefined);
    const [users, setUsers] = useState<Array<User>>([]);
    const [usersMap, setUsersMap] = useState<Map<string, User>>(new Map<string, User>());
    const [locations, setLocations] = useState<Array<Location>>([]);
    const [sessions, setSessions] = useState<Array<Session>>([]);
    const [sessionsMap, setSessionsMap] = useState<Map<string, Session>>(new Map<string, Session>());
    const [stats, setStats] = useState<Stats | undefined>(undefined);
    const [notifications, setNotifications] = useState<Notifications | undefined>(undefined);
    const [settings, setSettings] = useState<SettingsDoc>({chatEnabled: false} as SettingsDoc);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        (async () => {
            const auth = getAuth();
            let userId = "";
            await onAuthStateChanged(auth, async (authUser) => {
                if (!authUser) {
                    return;
                }
                const userCollection = query(collection(db, 'users'), where('authId', '==', authUser.uid));
                const userCollectionSnap = await getDocs(userCollection);
                await userCollectionSnap.forEach((userDoc) => {
                    userId = userDoc.id;
                    recordLastSeenAt(userId);
                    onSnapshot(doc(db, 'users', userId), (doc) => {
                        if (doc.exists()) {
                            let userToSet = doc.data() as User;
                            userToSet.id = doc.id;
                            setCurrentUser(userToSet);
                        }
                    });
                });
            });
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const usersCollection = query(collection(db, 'users'),
                orderBy('visitCount', 'desc'),
                limit(USERS_LIMIT)
            );
            const usersCollectionSnapshot = await getDocs(usersCollection);
            let users: Map<string, User> = new Map<string, User>();
            let usersArray: Array<User> = new Array<User>();
            usersCollectionSnapshot.forEach((doc) => {
                let userToSet = doc.data() as User;
                userToSet.id = doc.id;
                users.set(userToSet.id, userToSet);
                usersArray.push(userToSet);
            });
            setUsers(usersArray);
            setUsersMap(users);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            let locationsArray: Location[] = [];
            const locationsOnceCollection = query(collection(db, 'locations'),
                orderBy('name', 'asc'),
                limit(LOCATION_LIMIT));
            const locationsCollectionSnapshot = await getDocs(locationsOnceCollection);
            locationsCollectionSnapshot.forEach((doc) => {
                let tempLocation = doc.data() as Location;
                tempLocation.id = doc.id;
                if (!tempLocation.name) {
                    console.log('Location does not have a name: ' + doc.id);
                    tempLocation.name = 'Wetherspoons';
                }
                if(tempLocation.enabled == undefined || tempLocation.enabled) {
                    locationsArray.push(tempLocation);
                }
            });
            setLocations(locationsArray);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if(!currentUser) {
                return;
            }
            let sessions = new Array<Session>();
            let sessionsMap: Map<string, Session> = new Map<string, Session>();
            const sessionsOnceCollection = query(collection(db, 'sessions'),
                where('checkOutTime', '>', new Date()),
                orderBy('checkOutTime', 'desc'),
                limit(LOCATION_LIMIT));
            const sessionsCollectionSnapshot = await getDocs(sessionsOnceCollection);
            sessionsCollectionSnapshot.forEach((doc) => {
                let tempSession = doc.data() as Session;
                tempSession.id = doc.id;
                sessions.push(tempSession);
                sessionsMap.set(tempSession.userId, tempSession);
                if(tempSession.userId == currentUser.id) {
                    setUserSession(tempSession);
                }
                console.log(tempSession)
            });
            setSessionsMap(sessionsMap);
            setSessions(sessions);
        })();
    }, [currentUser]);

    useEffect(() => {
        (async () => {
            if(!currentUser) {
                return;
            }
            const statsOnceCollection = query(collection(db, 'stats'));
            const statsCollectionSnapshot = await getDocs(statsOnceCollection);
            statsCollectionSnapshot.forEach((doc) => {
                if(doc.id === "counts") {
                    const stats = doc.data() as Stats;
                    setStats(stats)
                }
            });

        })();
    }, [currentUser]);

    useEffect(() => {
        (async () => {
            if(!currentUser) {
                return;
            }
            onSnapshot(query(collection(db, 'notifications')), (snapshot) => {
                snapshot.docChanges().forEach(notifications => {
                    setNotifications(notifications.doc.data() as Notifications)
                });
            });
        })();
    }, [currentUser]);

    useEffect(() => {
        (async () => {
            if(!currentUser) {
                return;
            }
            onSnapshot(query(collection(db, 'settings')), (snapshot) => {
                snapshot.docChanges().forEach(settings => {
                    setSettings(settings.doc.data() as SettingsDoc)
                });
            });
        })();
    }, [currentUser]);

    return (
      <Router history={history}>
        <Route render={(props) => <Header {...props}
                                          sessions={sessions}
                                          session={userSession}
                                          notifications={notifications}
                                          currentUser={currentUser}
                                          setCurrentUser={setCurrentUser}
                                          settings={settings} />}/>
        <Route render={(props) => <Navigation {...props} currentUser={currentUser} settings={settings}/>}/>
        <Container>
          <Switch>

              {/*No auth required*/}
              <Route exact path="/" component={AuthEmail} />
              <Route exact path="/auth/response" component={AuthEmailResponse} />
              <Route exact path="/home" render={(props) => <Home {...props} settings={settings} currentUser={currentUser} stats={stats}/>}/>
              <Route exact path="/login" component={AuthEmail} />
              <Route exact path="/support" render={(props) => <Support {...props} settings={settings} currentUser={currentUser} />} />

              {/*Auth Required*/}
              <Route exact path="/pubs" render={(props) => <Locations {...props} locations={locations} />}/>
              <Route exact path="/map" render={(props) => <MapElement {...props} sessions={sessions} locations={locations} />}/>
              <Route exact path="/settings" render={(props) => <Settings {...props}
                                                                         currentUser={currentUser}
                                                                         locations={locations}
                                                                         settings={settings} />}/>
              <Route exact path="/admin/:id" component={Admin} />
              <Route exact path="/admin" component={Admin} />

              {/*Leaderboard specific*/}
              <Route exact path="/leaderboard/visits" render={(props) => <LeaderboardVisits {...props} users={users} currentUser={currentUser} sessionsMap={sessionsMap} />}/>
              <Route exact path="/leaderboard/verified" render={(props) => <LeaderboardVerified {...props} users={users} currentUser={currentUser} sessionsMap={sessionsMap} />}/>
              <Route exact path="/leaderboard/sessions" render={(props) => <LeaderboardSessions {...props} users={users} currentUser={currentUser} sessionsMap={sessionsMap} />}/>
              <Route exact path="/leaderboard/points" render={(props) => <LeaderboardPoints {...props} userMap={usersMap} users={users} currentUser={currentUser} sessionsMap={sessionsMap} />}/>
              <Route exact path="/leaderboard/year" render={(props) => <LeaderboardYear {...props} userMap={usersMap} users={users} currentUser={currentUser} sessionsMap={sessionsMap} />}/>

              {/*User specific*/}
              <Route exact path="/profile/:id/visits" render={(props) => <UserVisits {...props} currentUser={currentUser} locations={locations} />}/>
              <Route exact path="/profile/:id/sessions" render={(props) => <UserSessions {...props} currentUser={currentUser} locations={locations} />}/>
              <Route exact path="/profile/:id/comments" render={(props) => <UserComments {...props} currentUser={currentUser} />}/>
              <Route exact path="/profile/:id/photos" render={(props) => <UserPhotos {...props} currentUser={currentUser} />}/>
              <Route exact path="/profile/:id/visits/me" render={(props) => <MyProfile {...props} currentUser={currentUser} locations={locations} />}/>

              {/*Location specific*/}
              <Route exact path="/location/:id/comments" render={(props) => <LocationComments {...props} currentUser={currentUser} settings={settings} usersMap={usersMap} />}/>
              <Route exact path="/location/:id/photos" render={(props) => <LocationPhotos {...props} currentUser={currentUser} settings={settings} usersMap={usersMap} />}/>
              <Route exact path="/location/:id/comments/me" render={(props) => <MyLocation {...props} currentUser={currentUser} settings={settings} usersMap={usersMap} />}/>
              <Route exact path="/location/:id/visits" render={(props) => <LocationVisits {...props} currentUser={currentUser} settings={settings} usersMap={usersMap} session={userSession} />}/>
              <Route exact path="/location/:id/sessions" render={(props) => <LocationSessions {...props} currentUser={currentUser} settings={settings} usersMap={usersMap} />}/>

              {/*Hub specific*/}
              <Route exact path="/hub/visits" render={(props) => <Visits {...props} currentUser={currentUser} usersMap={usersMap} session={userSession} />}/>
              <Route exact path="/hub/comments" render={(props) => <Comments {...props} currentUser={currentUser} usersMap={usersMap} session={userSession} />}/>
              <Route exact path="/hub/updates" render={(props) => <Updates {...props} currentUser={currentUser} usersMap={usersMap} session={userSession}/>}/>
              <Route exact path="/hub/sessions" render={(props) => <Sessions {...props} currentUser={currentUser} usersMap={usersMap} />}/>
              <Route exact path="/hub/photos" render={(props) => <Photos {...props} currentUser={currentUser} usersMap={usersMap} session={userSession}/>}/>

              {/*Photo specific*/}
              <Route exact path="/photos/:tag" render={(props) => <PhotosViewer {...props} currentUser={currentUser} usersMap={usersMap} />}/>

              {/*Chat specific*/}
              <Route exact path="/chat/general"
                     render={(props) => <Chat {...props} notifications={notifications} selectedChannel={"GENERAL"} usersMap={usersMap}
                                              currentUser={currentUser} setNotifications={setNotifications} settings={settings} />}/>
              <Route exact path="/chat/support"
                     render={(props) => <Chat {...props} notifications={notifications} selectedChannel={"SUPPORT"} usersMap={usersMap}
                                              currentUser={currentUser} setNotifications={setNotifications} settings={settings} />}/>
          </Switch>
        </Container>
      </Router>
  );
};

export default App;
