import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { getMessaging } from "firebase/messaging";
import { getStorage } from "firebase/storage";
import ReactGA from "react-ga4";

const firebaseConfig = {
  apiKey: "AIzaSyCY-IMUNJ5d-1o5KUMhCUBVBnM_Yfklu5E",
  authDomain: "spoonstrackerlive.firebaseapp.com",
  projectId: "spoonstrackerlive",
  storageBucket: "spoonstrackerlive-storage",
  messagingSenderId: "1056208583974",
  appId: "1:1056208583974:web:2f74754a7466e6dc3ed5e7",
  measurementId: "G-27JNWX5874"
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const functions = getFunctions(app, "europe-west2");
const auth = getAuth(app);
const messaging = getMessaging(app);
const firebaseui = require('firebaseui');
const authUi = new firebaseui.auth.AuthUI(auth);
const storage = getStorage(app);

ReactGA.initialize("G-27JNWX5874");

export {db, functions, authUi, auth, messaging, storage}