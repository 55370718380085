import React from "react";
import "../nav/Navigation.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTags} from "@fortawesome/free-solid-svg-icons";

function LabelTag() {

    return (
        <>
            <div className={`user-tag d-inline-block`}>
                <FontAwesomeIcon className="animated zoomIn fa-solid" icon={faTags}/>
            </div>
        </>
    )
}

export default LabelTag;
