import React from "react";
import {Link} from "react-router-dom";
import {Message, User} from "../types/Types";
import {addAnchorTags, getDate, getMessageDate, getTime} from "../util/Util";
import UserTag from "../users/tag/UserTag";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
    message: Message;
    user: User | undefined;
    currentUser: User;
    hideMessage: (arg0: string) => void;
    previousMessage: Message | undefined;
}

function MessageRow(props: Props) {

    function isOwnMessage(): boolean {
        return props.currentUser.id == props.message.userId
    }

    function shouldDisplayName(): boolean {
        if(props.previousMessage == undefined) {
            return true;
        }
        return (props.message.userId != props.previousMessage.userId)
            || (props.message.createdAt as any).seconds - (props.previousMessage.createdAt as any).seconds > 300
    }
    return (
        <>
            {<div className={"chat-message mt-0 ml-1"}>
                {!isOwnMessage() && shouldDisplayName() && <div className={"ml-2 mt-2"} style={{marginBottom: "0px"}}>
                    <>
                        <div className={"d-inline-block"}>
                            <small><UserTag user={props.user} visit={undefined} session={undefined} padRight={false} hideLocationDot={false} linksToTab={"visits"} /></small>
                        </div>
                        <small style={{marginLeft: "5px", color: "grey"}}>{getMessageDate(props.message.createdAt)}</small>
                    </>
                </div>}
                <div className={`chat-bubble ${isOwnMessage() ? "chat-bubble-right" : ""}`} style={{marginTop: "4px", marginBottom: "4px"}}>
                    <span className={""}>
                        <span dangerouslySetInnerHTML={{ __html: addAnchorTags(props.message.payload)}} />
                    </span>
                    {props.currentUser.chatAdmin && <div className={"d-inline-block"}>
                        <small className={"ml-1"} style={isOwnMessage() ? {color: "#FFFFFF"} : {color: "#808080"}}>
                            {(props.user && (props.user.id == props.message.userId || props.user.admin)) &&
                            <FontAwesomeIcon style={{cursor: "pointer"}}
                                             onClick={() => {props.hideMessage(props.message.id)}}
                                             icon={faTrash} />}
                        </small>
                    </div>}
                </div>
            </div>}
        </>
    )
}

export default MessageRow;