import React from "react";
import "./Navigation.css";
import { NavLink, RouteComponentProps } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import {SettingsDoc, User} from "../types/Types";
import {getAuth, onAuthStateChanged} from "@firebase/auth";
import {collection, getDocs, query, where} from "@firebase/firestore";
import {db} from "../../firebase/FirebaseProvider";
import {isChatEnabled} from "../util/Util";

interface Props extends RouteComponentProps {
  currentUser: User | undefined;
  settings: SettingsDoc | undefined;
}

class Navigation extends React.PureComponent<Props, {}> {
  public componentDidMount() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (currentUser) => {
      if (!currentUser) {
        this.setState({ currentUser: null });
        return;
      }
      const userCollection = query(collection(db, 'users'), where('authId', '==', currentUser.uid));
      const userCollectionSnap = await getDocs(userCollection);
      userCollectionSnap.forEach((userDoc) => {
        this.setState({ currentUser: userDoc.data() as User });
        return;
      });
    });
  }

  public render() {
    return (
      <Container>
        <Nav variant="pills" className="my-3 mr-auto">
          {this.props.currentUser &&
          <>
            <NavLink className="nav-link" to="/pubs">
              Pubs
            </NavLink>
            <NavLink className="nav-link" to="/leaderboard/visits">
              Users
            </NavLink>
            <NavLink className="nav-link" to="/map">
              Map
            </NavLink>
            <NavLink className="nav-link" to="/hub/visits">
              Hub
            </NavLink>
            {this.props.currentUser
              && !this.props.currentUser.socialDisabled
              && isChatEnabled(this.props.currentUser, this.props.settings)
              && <NavLink className="nav-link" to="/chat/general" onClick={(e) => this.navigateToChat("general")}>
              Chat
            </NavLink>}
          </>}
          <div className="ml-auto">
            <NavLink className="nav-link" to="/support">
              Help
            </NavLink>
          </div>
        </Nav>
      </Container>
    );
  }

  private navigateToChat = (channel: string) => {
    //history.push("/chat/" + channel);
    //window.location.reload();
  }
}

export default Navigation;
