import React, {useEffect, useState} from "react";
import {Card, ListGroup} from "react-bootstrap";
import {NewsItem, User} from "../../types/Types";
import {collection, doc, getDoc, getDocs, limit, orderBy, query, where} from "@firebase/firestore";
import {db, functions} from "../../../firebase/FirebaseProvider";
import {httpsCallable} from "@firebase/functions";
import {NOTIFICATION_LIMIT} from "../../util/Util";
import UserNav from "../UserNav";
import UserDetails from "../UserDetails";
import CommentRow from "../../locations/profile/CommentRow";
import PageTracking from "../../auth/PageTracking";

interface Props {
    match: {
        params: {
            id: string
        }
    },
    currentUser: User | undefined
}

function UserComments(props: Props) {

    const [user, setUser] = useState<User | undefined>(undefined);
    const [comments, setComments] = useState<Array<NewsItem> | undefined>(undefined);

    useEffect(() => {
        (async () => {
            const userRef = doc(db, 'users', props.match.params.id);
            const docSnap = await getDoc(userRef);
            if (docSnap.exists()) {
                let userToSet = docSnap.data() as User;
                userToSet.id = docSnap.id;
                setUser(userToSet);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await loadComments();
        })();
    }, [user]);

    const loadComments = async() => {
        if(!user) {
            return;
        }

        let comments: Array<NewsItem> = [];
        const commentsOnceCollection = query(collection(db, 'comments'),
            where('userId', '==', user.id),
            where('hidden', '==', false),
            orderBy('lastModified', 'desc'),
            limit(NOTIFICATION_LIMIT));
        const commentsOnceCollectionSnapshot = await getDocs(commentsOnceCollection);
        commentsOnceCollectionSnapshot.forEach((doc) => {
            let tempComment = doc.data() as NewsItem;
            tempComment.id = doc.id;
            comments.push(tempComment);
        });
        setComments(comments);
    };

    const hideComment = async (commentId: string) => {
        const hideCommentApi = httpsCallable(functions, 'hideComment');
        await hideCommentApi(
            {
                commentId
            }
        )
        await loadComments();
    };

    return (
        <>
            <PageTracking />
            {props.currentUser && <UserDetails user={user} />}
            {props.currentUser && user && <UserNav currentUser={props.currentUser} user={user} />}
            {props.currentUser && <Card className={"mb-3"}>
                <ListGroup variant="flush">
                    {!comments && <div className={'loading'}>
                        Loading...
                    </div>}
                    {(comments && comments.length == 0) &&<div className={'loading'}>
                        No comments yet
                    </div>}
                    {comments && comments
                        .map((comment: NewsItem, index: number) => (
                        props.currentUser && <CommentRow key={comment.id} user={props.currentUser} comment={comment} deleteComment={() => hideComment(comment.id)} commentUser={user}/>
                    ))}
                </ListGroup>
            </Card>}
        </>
    )
}

export default UserComments;