import React, {useEffect} from "react";
import {Card} from "react-bootstrap";
import {SettingsDoc, Stats, User} from "../types/Types";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import history from "../util/History";
import {faCoins} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";
import {formatNumber, formatNumberToDays, isChatEnabled} from "../util/Util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ChampionTag from "../tag/ChampionTag";
import AdminTag from "../tag/AdminTag";
import SupporterTag from "../tag/SupporterTag";
import PageTracking from "../auth/PageTracking";

interface Props {
    currentUser: User | undefined
    stats: Stats | undefined
    settings: SettingsDoc | undefined
}

function Home(props: Props) {

    useEffect(() => {
        (async () => {
            const auth = getAuth();
            onAuthStateChanged(auth, async (currentUser) => {
                if (!currentUser) {
                    history.push('/');
                    return;
                }
            });
        })();
    }, []);

    return (
        <>
            <PageTracking />
            {!props.currentUser && <>
                <Card>
                    <div className={'loading'}>Loading...</div>
                </Card>
            </>}
            {props.currentUser && <>
                <div><h5>Welcome {props.currentUser && <strong>{props.currentUser.name}!</strong>}</h5></div>
                {!props.currentUser.socialDisabled && <Card className={"mt-3 mb-3"}>
                    <Card.Header>
                        <div><b>Whats new?</b></div>
                    </Card.Header>
                    <Card.Body>
                        <ul className={"bullet-points"}>
                            <li><div className={"mb-2"}>Earn points <FontAwesomeIcon className={"ml-1 mr-1"} icon={faCoins}/> by checking into 'Spoons. More information available <Link to={"/support#points-help"}>here</Link>.</div></li>
                            <li><div className={"mb-2"}>A new <Link to={"/leaderboard/year"}>board</Link> keeps track of who is ahead, with the ability to see previous years rankings.</div></li>
                            <li><div className={"mb-2"}>The winner of a particular year will earn a digital badge <ChampionTag year={new Date().getFullYear()} active={false} /> and a physical prize.</div></li>
                            <li><div>We now have a <Link to={"/location/af698ca74-2996-4a05-aad7-93b2f383cad9/photos"}>photos</Link> section under each pub which allows the upload and viewing of photos.</div></li>
                        </ul>
                    </Card.Body>
                </Card>}
                {props.stats && <Card className={`mb-3 ${props.currentUser.socialDisabled ? "mt-3" : ""}`}>
                    <Card.Header>
                        <div><b>'Spoons Tracker stats</b></div>
                    </Card.Header>
                    <Card.Body>
                        <ul className={"bullet-points"}>
                            <li><div className={"mb-2"}><b>{formatNumber(props.stats.activeUsers)}</b> active users registered, with <b>{formatNumber(props.stats.recentUsers)}</b> having logged in during the last week.</div></li>
                            <li><div className={"mb-2"}><b>{formatNumber(props.stats.visits)}</b> visits recorded.</div></li>
                            {!props.currentUser.socialDisabled && <li><div className={"mb-2"}><b>{formatNumber(props.stats.sessions)}</b> check-ins, for a total check-in time of <b>{formatNumberToDays(props.stats.hoursCheckedIn)} days.</b></div></li>}
                            {!props.currentUser.socialDisabled && <li><div className={"mb-2"}><b>{formatNumber(props.stats.comments)}</b> pub page comments.</div></li>}
                            {!props.currentUser.socialDisabled && <li><div className={"mb-2"}><b>{formatNumber(props.stats.messages)}</b> chat messages.</div></li>}
                            {!props.currentUser.socialDisabled && <li><div><b>{formatNumber(props.stats.photos)}</b> photos uploaded.</div></li>}
                        </ul>
                    </Card.Body>
                </Card>}
            </>}
        </>
    )
}

export default Home;