import React, {Component} from "react";
import {getAuth, onAuthStateChanged} from "@firebase/auth";
import history from "../util/History";

class AuthCheck extends Component<{}, {}> {
  componentDidMount() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (currentUser) => {
      if (!currentUser) {
        history.push('/');
        return;
      }
    });
  }

  render() {
    return (
        <></>
    )
  }
}

export default AuthCheck;