import React, {Component, useEffect, useState} from "react";
import {Card, ListGroup} from "react-bootstrap";
import {Location, Comment, NewsItem, User, CommentInfo, Visit, Session, SettingsDoc} from "../../types/Types";
import {collection, doc, getDoc, getDocs, limit, orderBy, query, where} from "@firebase/firestore";
import {db, functions} from "../../../firebase/FirebaseProvider";
import LocationNav from "./LocationNav";
import LocationDetails from "./LocationDetails";
import {httpsCallable} from "@firebase/functions";
import {getDate, getTime, LOCATION_LIMIT, NOTIFICATION_LIMIT, sanitizeHtmlString} from "../../util/Util";
import LocationCheckIn from "./LocationCheckIn";
import {Link} from "react-router-dom";
import UserTag from "../../users/tag/UserTag";
import SessionRow from "../../shared/SessionRow";
import PageTracking from "../../auth/PageTracking";

interface Props {
    match: {
        params: {
            id: string
        }
    },
    currentUser: User | undefined,
    usersMap: Map<string, User> | undefined
    settings: SettingsDoc
}

function LocationSessions(props: Props) {

    const [sessions, setSessions] = useState<Array<Session> | undefined>(undefined);
    const [location, setLocation] = useState<Location | undefined>(undefined);

    useEffect(() => {
        (async () => {
            const locationRef = doc(db, 'locations', props.match.params.id);
            const docSnap = await getDoc(locationRef);
            if (docSnap.exists()) {
                let locationToSet = docSnap.data() as Location;
                locationToSet.id = docSnap.id;
                setLocation(locationToSet);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            await loadAllData();
        })();
    }, [location]);

    const loadAllData = async() => {
        if(!location) {
            return;
        }
        // Load location sessions
        let sessions: Array<Session> = [];
        const sessionOnceCollection = query(collection(db, 'sessions'),
            where('locationId', '==', location.id),
            orderBy('checkInTime', 'desc'),
            limit(NOTIFICATION_LIMIT));
        const sessionOnceCollectionSnapshot = await getDocs(sessionOnceCollection);
        sessionOnceCollectionSnapshot.forEach((doc) => {
            let session = doc.data() as Session;
            if(isUserSocial(session.userId)) {
                sessions.push(session);
            }
        });
        setSessions(sessions);
    };

    const isUserSocial = (visitId: string): boolean => {
        if(!props.usersMap) {
            return false;
        }
        let user: User | undefined = props.usersMap.get(visitId);
        if(!user) {
            return false;
        }
        return !user.socialDisabled;
    };

    return (
        <>
            <PageTracking />
            {props.currentUser && <LocationDetails location={location} currentUser={props.currentUser} />}
            {location && props.currentUser && !props.currentUser.socialDisabled && <LocationCheckIn currentUser={props.currentUser} location={location}/>}
            {location && <LocationNav settings={props.settings} currentUser={props.currentUser} locationId={location.id}/>}
            <Card>
                {location && sessions && sessions.length > 0 && <Card.Header>
                    <div className={"d-inline-block float-left"}>
                        Total: <b>{sessions.length}</b>
                    </div>
                </Card.Header>}
                <ListGroup variant="flush">
                    {sessions && location && sessions
                        .map((session: Session, index: number) => (
                        props.usersMap && <SessionRow key={session.id}
                                                      displayName={true}
                                                      displayLocation={false}
                                                      session={session}
                                                      user={props.usersMap.get(session.userId)}
                                                      displayBonus={session.bonus || false} />
                    ))}
                    {!sessions && <>
                        <div className={"loading"}>Loading...</div>
                    </>}
                    {(sessions && sessions.length == 0) &&<div className={'loading'}>
                        No check-ins yet
                    </div>}
                </ListGroup>
            </Card>
        </>
    )
}

export default LocationSessions;