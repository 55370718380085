import React from "react";
import "../nav/Navigation.css";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import {User} from "../types/Types";

interface Props {
    currentUser: User | undefined;
}

function HubNav(props: Props) {

    return (
        <Container>
            <Nav variant="tabs" className="mb-2 mr-auto sub-menu-nav">
                <>
                    <NavLink className="nav-link" to="/hub/visits">
                        Visits
                    </NavLink>
                    {(props.currentUser && !props.currentUser.socialDisabled) && <NavLink className="nav-link" to="/hub/sessions">
                        Check-ins
                    </NavLink>}
                    <NavLink className="nav-link" to="/hub/comments">
                        Comments
                    </NavLink>
                    <NavLink className="nav-link" to="/hub/photos">
                        Photos
                    </NavLink>
                </>
            </Nav>
        </Container>
    )
}

export default HubNav;
